import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location, CommonModule } from '@angular/common';
import { RouterOutlet, RouterModule, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppService } from '../app.service';
import { AuthService } from '../auth.service';
import { HomeService } from '../home/home.service';

//import { MyCoachComponent } from './my-coach/my-coach.component';

import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  selector: 'app-my-space',
  standalone: true,
  imports: [RouterOutlet, RouterModule, MatSidenavModule, CommonModule],
  templateUrl: './my-space.component.html',
  styleUrl: './my-space.component.scss'
})
export class MySpaceComponent implements OnInit, OnDestroy {
  loading = false
  pageDisplayed = ''
  desktopDevice = false

  loaderSubscription!: Subscription
  pageSubscription!: Subscription

  constructor(
    private appService: AppService,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private homeService: HomeService
  ){}

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)
    this.pageSubscription = this.homeService.pageDisplayed.subscribe(res => this.pageDisplayed = res)

    // gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }

    this.desktopDevice = this.appService.desktopDevice

    if(this.location.path() === '/home/my-space' && this.desktopDevice) {
      this.router.navigate(['/home/my-space/my-profile'])
    }
  }

  displayPage(page: string){
    this.homeService.pageDisplayed.next(page)
  }

  logOut(){
    this.authService.logged$.next(false)
    this.router.navigate(['/login'])
    this.authService.authDisconnect()
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
    this.pageSubscription.unsubscribe()
  }
}
