<section class="notifications">
  <app-go-back-button (click)="goBack()"></app-go-back-button>

  <div class="top">
      <h3><span class="regular">My</span> Notifications</h3>
  </div>

  @if(loading){
    <app-loader></app-loader>
  }    

  @if(notifications.length === 0){
    <p>No notification</p>
  } @else {
    <section class="notifications-container">
      <hr class="separation-line">
      @for(n of notifications; track n){
        @if(n.type === 'contact'){
          @if(n.user){
            <button type="button" class="item" [ngStyle]="{borderColor: n.readed === 0 ? '#FFC46A': 'transparent'}" (click)="storeUserAndNavigate(n.user.id)"  routerLinkActive="router-link-active">
              <div class="photo-container">
                @if(n.user.photo){
                  <img [src]="n.user.photo" alt="">
                } @else {
                  <img src="../../../assets/icons/icon_user_96X96.png" alt="" class="no-photo">
                }
              </div>
              <div class="message">
                <div class="description">
                  You have a new match with <span class="firstname">{{n.user.firstname}}</span>.
                </div>
                <div class="time">
                  {{timeExpression}}
                </div>
              </div>
            </button>
            <hr class="separation-line">
          }
        } @else if (n.type === 'crush') {
            @if(n.story && n.story.likes > 0){
              <button class="item story" [ngStyle]="{borderColor: n.readed === 0 ? '#FFC46A': 'transparent'}">
                <div class="left">
                  @if(n.story.users && n.story.users.length > 0){
                    <div class="photo-container crush-photo-container">
                      @if(n.story.users.length > 1){
                        <div class="overlay">+ {{n.story.users.length -1}}</div>
                      }
                      
                      <img [src]="n.story.users[0].photo" alt="">
                    </div>
                  }
      
                  <div class="message">
                    <div class="description">
                      {{n.story.likes}} person{{n.story.likes > 1 ? 's' : ''}} liked your story. 
                    </div>
                    <div class="time">
                      {{n.timeExpression}}
                    </div>
                  </div>
                </div>

                <div class="right">
                  <div class="story-photo">
                    <img [src]="n.story.photos[0]" alt="">
                  </div>
                </div>
              </button>
              <hr class="separation-line">
            }
          } @else if(n.type === 'suggestion'){
            @if(n.user){
              <button type="button" class="item" [ngStyle]="{borderColor: n.readed === 0 ? '#FFC46A': 'transparent'}" (click)="storeUserAndNavigate(n.user.id)"  routerLinkActive="router-link-active">
                <div class="photo-container">
                  @if(n.user.photo){
                    <img [src]="n.user.photo" alt="">
                  } @else {
                    <img src="../../../assets/icons/icon_user_96X96.png" alt="" class="no-photo">
                  }
                </div>
  
                <div class="message">
                  <div class="description">
                    <span class="firstname">{{n.user.firstname}}</span> saw your profile.
                  </div>
                  <div class="time">
                    {{n.timeExpression}}
                  </div>
                </div>
              </button>
              <hr class="separation-line">
            }
          }
        }
    </section>
  }
</section>
  