import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, RouterOutlet } from '@angular/router';
import { CommonModule, DOCUMENT, Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { AppService } from '../app.service';
import { HomeService } from '../home/home.service';
import { ProfileService } from './profile.service';
import { AuthService } from '../auth.service';

import { LoaderComponent } from '../shared/loader/loader.component';
import { GoBackButtonComponent } from '../shared/go-back-button/go-back-button.component';

import { User } from '../shared/interfaces/user';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [LoaderComponent, GoBackButtonComponent, RouterModule, CommonModule, RouterOutlet],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
})
export class ProfileComponent implements OnInit, OnDestroy {
  private window: (Window & typeof globalThis) | null

  profileId!: number
  profiles!: User[]
  profile!: User | null
  loading = false
  userAge!: number
  desktopDevice = false
  loaderSubscription!: Subscription
  routeDataSubscription!: Subscription
  profileSubscription!: Subscription
  profileLiked = false

  constructor(
    private route: ActivatedRoute,
    private appService: AppService,
    private homeService: HomeService,
    private location: Location,
    private profileService: ProfileService,
    private router: Router,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) { 
    this.window = this.document.defaultView

    if(this.window){
      this.window.addEventListener('popstate', (event) => {
        const popStateTarget = event.currentTarget as Window

        if(popStateTarget.location.pathname.includes('profile')) {
          this.homeService.pageDisplayed.next('profile')
        }
      }, false);
    }
  }

  ngOnInit(): void {
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    if(this.router.url.includes('matches')){
      this.profileSubscription = this.profileService.profile.subscribe(res => {
        this.profile = res
      })
    }

    this.routeDataSubscription = this.route.params.subscribe(params => {
      const id = params['id']

      this.authService.getProfile(id).subscribe(res => {
        this.profile = res
        this.profileService.profile.next(res)
      })
    })

    this.homeService.pageDisplayed.next('profile')

    this.profileService.profileLiked.subscribe(res => {
      this.profileLiked = res
    })
    /* 
      firstname: "firstname"
      id: 212036
      new: false
      photo: "https://assets.staging.dating.dv-content.io/users/212036/profile/crop/6734bd0706b8e_1731509511.jpeg"
      sex: "man"
      updated_at: "2024-11-13 15:03:45"
  */
    //gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }

    this.desktopDevice = this.appService.desktopDevice
  }

  goBack(){
    this.location.back()
  }

  setPageDisplayed(){
    this.homeService.pageDisplayed.next('new-encounters')
  }

  rejectProfile(){

  }
  likeProfile(){

  }
  contactProfile(){

  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
    if(this.profileSubscription) this.profileSubscription.unsubscribe()
    this.routeDataSubscription.unsubscribe()
  }
}
