import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import dayjs from 'dayjs';

import { HomeService } from '../../home/home.service';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-match-dialog',
  standalone: true,
  imports: [],
  templateUrl: './match-dialog.component.html',
  styleUrl: './match-dialog.component.scss'
})
export class MatchDialogComponent implements OnInit, OnDestroy {
  // create reference to dialog and pass the data type to pass to parent component when dialog is closed (boolean) to trigger save or cancel
  dialogRef = inject<DialogRef<boolean>>(DialogRef<boolean>);
  data = inject(DIALOG_DATA);

  matchProfile!: User | null
  userAge!: number

  constructor(
    private homeService: HomeService
  ){}

  ngOnInit(){
    this.matchProfile = this.data
    this.homeService.dialogDisplayed.next(true)

    if(this.matchProfile && this.matchProfile.birthdate){
      const [y,m,d] = this.matchProfile.birthdate.split('-')
      const date = `${y}-${m}-${d}`
      const birthDate = dayjs(date)

      this.userAge = dayjs().diff(birthDate, 'year')
    }
  }

  ngOnDestroy(){
    this.homeService.dialogDisplayed.next(false)
  }
}
