<div class="match-screen">
  <img src="assets/icons/icon_match.svg" alt="" class="match-icon">
  <img src="assets/icons/icon_match.svg" alt="" class="match-icon">
  <img src="assets/icons/icon_match.svg" alt="" class="match-icon">
  <img src="assets/icons/icon_match.svg" alt="" class="match-icon">

  <h1>It's a <span class="match">Match!</span></h1>
  <p class="description">It appears that you and {{matchProfile?.firstname}} have feelings for each other.</p>
  
  <div class="photo-container">
    <img [src]="matchProfile?.main_photo?.url" alt="">
  </div>
  <div class="info">
    <h2>{{matchProfile?.firstname}}</h2>
    <p class="age-city">{{userAge}} years - {{matchProfile?.city?.name}}</p>
    <div class="attraction">
      <img src="assets/icons/icon_searching_for.svg" alt="" class="search-icon">
      <p class="genre">{{matchProfile?.attraction === 'man' ? 'Men' : 'Women'}}</p>
    </div>
    
  </div>
  <button type="button" class="submit" (click)="dialogRef.close(true)">Send a message</button>
  <button type="button" class="text-link" (click)="dialogRef.close()">Keep using Swipi</button>
</div>