<div class="user-tiny" >
  <div class="content" [class.border]="new && newMessages">
      @if(origin === 'conversation'){
        <app-go-back-button (click)="goBack()"></app-go-back-button>
      }

      <div class="photo-container" [class.logged-in]="loggedIn">
        <button type="button" class="user-tiny-photo"><!--  (click)="viewProfile(relatedUser.id)" -->
          @if(data?.user?.photo){
            <img [src]="data?.user?.photo" alt=""/>
          } @else {
            <div class="no-photo">
              <img src="assets/icons/icon_user_96X96.png" alt="">
            </div>
          }
        </button>
      </div>
  
      <button type="button" class="user-tiny-data" (click)="openTalk(data)">
        @if(user.value){
          <h5 class="user-tiny-data-name">
            {{ user.value.firstname }}
          </h5>
        } @else {
          <h5 class="user-tiny-data-name">
            {{ data?.user?.firstname }}
          </h5>
        }
        
        @if(origin === "chat"){
          @if(data?.message){
            <p class="user-tiny-data-message" [innerHTML]="data?.message"></p>
          }
  
          <div class="user-tiny-time-date">
            @if(data?.today){
              <ng-container>Today</ng-container>
            }
            
            @if(data?.yesterday){
              <ng-container>Yesterday</ng-container>
            }
            
            @if(!data?.yesterday && !data?.today){
              <ng-container>{{ data?.time | date: 'd MMM yyy' }}</ng-container>
            }
          </div>
        }
      </button>
  
      @if(origin === 'chat'){
        @if(new && newMessages > 0){
          <div class="badge">
            {{newMessages}}
          </div>
        }
      } @else {
        <button type="button" class="contextual-menu closed show" #contextualMenuClosed (click)="openConversationDialog()">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </button>
      }
    </div>
    <!-- version chat : photo, firstname, début du dernier message, date et heure du dernier message
    version conversation: photo, firstname, menu -->
</div>

