import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { FormGroup, ReactiveFormsModule, FormsModule, FormControl, Validators, FormGroupDirective, ControlContainer } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AuthService } from '../../auth.service';
import { AppService } from '../../app.service';

import { User } from '../interfaces/user';
import { dateValidator } from '../validators/dateValidator';

@Component({
  selector: 'app-birthdate-input',
  standalone: true,
  imports: [TitleCasePipe, ReactiveFormsModule, FormsModule],
  templateUrl: './birthdate-input.component.html',
  styleUrl: './birthdate-input.component.scss',
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class BirthdateInputComponent implements OnInit, OnDestroy {
  @Input() user!: User
  @Input() birthdateFormGroup!: string

  @Output() birthdayDialogData = new EventEmitter<{errorTitle: string; errorMessage: string;} | null>()
  //@Output() emitBirthdate = new EventEmitter<{day: string, month: string, year: string}>() 

  days: number[] = []
  months: {label: string, id: string}[] = [
    {
      label: 'january', 
      id: '01'
    }, 
    {
      label: 'february', 
      id: '02'
    }, 
    {
      label: 'march', 
      id: '03'
    }, 
    {
      label: 'april', 
      id: '04'
    }, 
    {
      label: 'may', 
      id: '05'
    }, 
    {
      label: 'june', 
      id: '06'
    }, 
    {
      label: 'july', 
      id: '07'
    }, 
    {
      label: 'august', 
      id: '08'
    }, 
    {
      label: 'september', 
      id: '09'
    }, 
    {
      label: 'october', 
      id: '10'
    }, 
    {
      label: 'november', 
      id: '11'
    }, 
    {
      label: 'december', 
      id: '12'
    }
  ]
  years: number[] = []
  youngestYear!: number
  oldestYear!: number
  loading = false
  birthdate: {day: string, month: string, year: string} = {day: '', month: '', year: ''}

  openDialog = false
  errorTitle: string = ''
  errorMessage: string = ''

  childForm!: FormGroup
  birthdateForm!: FormGroup
  loaderSubscription!: Subscription

  constructor(
    private appService: AppService,
    private parentForm: FormGroupDirective,
    private authService: AuthService
  ){
    // on ajoute les jours
    for(let i = 1; i <= 31; i++){
      this.days.push(i)
    }

    // on ajoute les années, la plus récente étant année d'aujourd'hui -18
    this.youngestYear = new Date().getFullYear() - 18
    this.oldestYear = new Date().getFullYear() - 99
    for(let i = this.youngestYear; i >= this.oldestYear; i--){
      this.years.push(i)
    }
  }

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)
    
    this.childForm = this.parentForm.form

    let birthdate: string | undefined = ''
    const userStoredLocally = !!this.authService.get('user')

    if(this.user && userStoredLocally){
      this.user = JSON.parse(this.authService.get('user'))
      birthdate = this.user.birthdate
    }

    //reformattage du jour si nécessaire
    let day = ''

    if(birthdate && birthdate!== ''){
      const dayArr = birthdate.slice(8).split('')
      dayArr[0] === '0' ? day = dayArr[1] : day = `${dayArr[0]}${dayArr[1]}`
    }

    this.childForm.addControl(
      'birthdate', 
      new FormGroup({
        birthDayCtrl: new FormControl<string>(day || '', [Validators.required]),
        birthMonthCtrl: new FormControl<string>(birthdate.slice(5, 7) || '', [Validators.required]),
        birthYearCtrl: new FormControl<string>(birthdate.slice(0, 4) || '', [Validators.required]),
      }, {
        validators: dateValidator()
      })
    )

    this.childForm.get('birthdate')?.statusChanges.subscribe(() => {
      this.openDialog = false

      if(!this.childForm.get('birthdate')?.errors && !this.childForm.get('invalidDate')?.errors) {
        console.log('pas d erreur')
        this.birthdayDialogData.emit(null)}

      if(this.childForm.get('birthdate')?.errors?.['underAged']){
        this.birthdayDialogData.emit({
          errorTitle: "Sorry",
          errorMessage: "You must be over 18 years old to subscribe"
        })
      }
      if(this.childForm.get('birthdate')?.errors?.['invalidDate']){
        this.birthdayDialogData.emit({
          errorTitle: "Invalid date",
          errorMessage: "Given date does not exist. Please check your data and try again."
        })
      }
    })
  }

  onClick(event: Event){
    event.stopPropagation()
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
