@if(loading){
    <app-loader></app-loader>
}

<section class="profile-section">
    @if(profileLiked){
      <div class="like-success">
        <img src="assets/icons/icon_love_32.svg" alt="" class="like-icon">
        <p>You liked {{profile?.firstname}}'s profile!</p>
      </div>
    }
  
    <header class="header">
        <app-go-back-button (click)="setPageDisplayed(); goBack()"></app-go-back-button><!-- [routerLink]="['/home/new-encounters']"  -->
        <nav class="profile-menu">
            <ul>
                <li>
                    <a [routerLink]="['space']" routerLinkActive="selected" #profileSpaceLink="routerLinkActive" [routerLinkActiveOptions]="{exact: true}"> <!--  [routerLink]="['profile', profile.id]" -->
                        <img 
                            [src]="profileSpaceLink.isActive ? '../../assets/icons/icon_myspace_selected.svg' : '../../assets/icons/icon_myspace_menu.svg'" 
                            alt=""
                            class="icon"
                        >
                    </a>
                </li>
                <li>
                    <a [routerLink]="['stories']" routerLinkActive="selected" #profileStoriesLink="routerLinkActive" class="icon">
                        <img 
                            [src]="profileStoriesLink.isActive ? '../../assets/icons/icon_stories_selected.png' : '../../assets/icons/icon_stories.svg'" 
                            alt=""
                            class="icon"
                        >
                    </a>
                </li>
            </ul>
        </nav>
    </header>

    <router-outlet></router-outlet>
</section>