import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location, TitleCasePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

import { HomeService } from '../../home/home.service';
import { AppService } from '../../app.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

@Component({
  selector: 'app-my-subscription',
  standalone: true,
  imports: [LoaderComponent, GoBackButtonComponent, TitleCasePipe],
  templateUrl: './my-subscription.component.html',
  styleUrl: './my-subscription.component.scss'
})
export class MySubscriptionComponent implements OnInit, OnDestroy {
  desktopDevice = false
  loading = false
  loaderSubscription!: Subscription
  serviceName = environment.serviceName

  constructor(
    private location: Location,
    private homeService: HomeService,
    private appService: AppService
  ){}

  ngOnInit(){
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }
    this.desktopDevice = this.appService.desktopDevice
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)
  }

  goBack(){
    if(this.desktopDevice) {
      this.homeService.pageDisplayed.next('')
      this.location.back()
    } else {
      this.homeService.pageDisplayed.next('')
    }
  }

  ngOnDestroy() {
    this.loaderSubscription.unsubscribe()
  }
}
