import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';
import SHA256 from 'crypto-js/sha256';
import MD5 from 'crypto-js/md5';
import utf8 from 'utf8';
//import CryptoJS from 'crypto-js';
import SHA1 from 'crypto-js/sha1';
import { Router } from '@angular/router';

import { AppService } from './app.service';
//import { ServiceService } from '../service/service.service';

import { User } from './shared/interfaces/user';
import { Gender } from './shared/enum/Gender';

export interface ResetPasswordResponse {
  code: number,
  url: string
}

export interface RegisterResponse {
  code: number,
  datas?: {
    user: {
      user_id: number,
      userdve_id: string
    },
    api_token: string | undefined,
    display_last_awards: boolean
  }
  error?: {
    error_code?: number,
    level?: string,
    message?: string
  }
}

export enum Attraction {
  woman = Gender.man,
  man = Gender.woman
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  subscriptionFormData: Record<string, string | number | null> = {
    attraction: '',
    id: null,
    firstname: '',
    birthdate: '',
    sex: '',
    photo: '',
    city_id: null,
    email: '',
    password: ''
  }

  token!: string | undefined
  readonly country = environment.country
  public logged$ = new BehaviorSubject<boolean>(false)
  public authMe$ = new BehaviorSubject<User | null>(null)
  public user = new BehaviorSubject<User | null>(null)
  public fingerPrint: string = ''
  public userBilledInfos = null
  public userLogin = null
  private localStorage: Storage | null = null
  savedImageUrl$ = new BehaviorSubject<string>('')

  get authMe(): Observable<User | null> {
    return this.authMe$.asObservable()
  }

  get logged(): Observable<boolean> {
    this.logged$.next(!!this.get('token'))
    return this.logged$.asObservable()
  }
  
  setSubscriptionFormData(key: string, value: string | number | null){
    this.subscriptionFormData[key] = value
  }

  constructor(
    private http: HttpClient, 
    private appService: AppService, 
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.localStorage = this.document.defaultView?.localStorage || null;
  }

  get(key: string): string {
    //console.log(this.localStorage?.getItem(key))
    return this.localStorage?.getItem(key) || '';
  }

  set(key: string, value: string): void {
    this.localStorage?.setItem(key, value);
  }

  remove(key: string): void {
    return localStorage.removeItem(key);
  }

  setAuthMe(value: User) {
    this.authMe$.next(value);
    this.user.next(value)
  }

  authLogin(login: string, password: string) : Observable<User> {
    return this.http
      .get<{body :{ user: User; api_token: string }}>(
        `${this.appService.API}/connect/login/${login}/${this.dveHash(password)}/${this.appService.serviceId}`
      )
      .pipe(
        map(
          (res) => {
            console.log(res.body.user)
            
            this.set('user', JSON.stringify(res.body.user))
            this.set('user_id', (res.body.user.id).toString())
            this.set('token', res.body.api_token);

            this.setAuthMe(res.body.user)
            this.user.next(res.body.user)

            return res.body.user;
          }),
          catchError((error) => {
            console.error(error)
            return throwError(() => error);
          }),
        );
  }

  deleteAccount(password: string): Observable<{error: boolean, message: string}> {
    return this.http
      .delete<{ error_code: number }>(`${this.appService.API}/api/user?password=${this.dveHash(password)}`)
      .pipe(
        map(() => {
          return {error: false, message: 'Account deleted'};
        }),
        catchError((res) => {
          if(res.error.error_code === 20){
            return of({error: true, message: 'Incorrect password'})
          }
          return of({error: true, message: 'We could not delete your password.'})
        }),
      );
  }

  authDisconnect() {
    this.localStorage?.clear()
    this.authMe$.next(null)
    this.user.next(null)
    this.router.navigate(['/login'])
  }

  authCheckToken(token: string): Observable<{
    userdveid: number;
    transactionid: string;
    operationid: string;
    productid: number;
    subscription_token: string;
  }> {
    this.fingerPrint = this.setFingerPrint();

    return this.http
      .get<{
        data: {
          userdveid: number;
          transactionid: string;
          operationid: string;
          productid: number;
          subscription_token: string;
        };
      }>(`${this.appService.API}/user/subscription/FR/69/${this.fingerPrint}?authtoken=${token}`
      )
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  setFingerPrint(): string {
    return (
      Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    );
  }

  authSubscribe(): Observable<RegisterResponse> {
    const passhash = this.dveHash(this.subscriptionFormData['password'] as string);

    const body = {
      attraction: this.subscriptionFormData['sex'] as string === 'man' ? Gender.woman : Gender.man,
      birthdate: this.subscriptionFormData['birthdate'] as string || this.get('birthdate'),
      city_id: this.subscriptionFormData['city_id'] as number || this.get('city_id'),
      firstname: this.subscriptionFormData['firstname'] as string || this.get('firstname'),
      email: this.subscriptionFormData['email'] as string || this.get('email'),
      password: passhash,
      sex: this.subscriptionFormData['sex'] as string || this.get('sex'),
      product_id: this.appService.serviceId
    };

    return this.http.post<RegisterResponse>(`${this.appService.API}/registration/register`, body)
    .pipe(
      map((res) => {
        return res
      })
    )
  }

  /* authSubscribePostBilling(value: {
    subscriptionId: string;
    productId: number;
    transactionId: number;
    operationId: string;
    userDVEId: number;
    attraction: string;
    birthdate: string;
    city_id: number;
    firstname: string;
    email: string;
    password: string;
    sex: string;
  }): Observable<any> {
    const passhash = this.dveHash(value.password);
    const token = this.setToken(value.subscriptionId);
    this.set('token', token);

    const body = {
      product_id: value.productId,
      transaction_id: value.transactionId,
      operation_id: value.operationId,
      userdve_id: value.userDVEId,
      attraction: value.attraction,
      birthdate: value.birthdate,
      city_id: value.city_id,
      firstname: value.firstname,
      email: value.email,
      password: passhash,
      sex: value.sex,
      service: this.appService.serviceId
    };

    return this.http
      .post<{ user: { user_id: number; userdve_id: number }; api_token: string }>(
        `${this.appService.API}/user/subscription/withbilling`,
        body
      )
      .pipe(
        map((res) => {
          this.remove('token');
          return res;
        })
      );
  } */

  setToken(subscription: string): string {
    const hash = SHA256(utf8.encode(this.fingerPrint));
    return btoa(utf8.encode(subscription + '__' + hash));
  }

  authUpdate(): Observable<User> {
    const body = {
      description: this.subscriptionFormData['description'],	
      firstname: this.subscriptionFormData['firstname'],
      birthdate: this.subscriptionFormData['birthdate'],
      Sexual: this.subscriptionFormData['sex'],
      city_id: this.subscriptionFormData['city']
    }

    return this.http.put(`${this.appService.API}/api/user`, body).pipe(
      mergeMap(() => {
        return this.getProfile(Number(this.get('user_id'))).pipe(
          map((user) => {
            this.setAuthMe(user)
            return user
          }),
          catchError((error) => {
            return throwError(() => error)
          }),
        );
      })
    );
  }

  authUpdatePassword(value: { old: string; new: string }): Observable<{code: number} | object> {
    return this.http
      .put(
        `${this.appService.API}/user/password?old_password=${this.dveHash(
          value.old
        )}&new_password=${this.dveHash(value.new)}`,
        {}
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  authRecoverPassword(email: string): Observable<ResetPasswordResponse> {
    return this.http
      .get<ResetPasswordResponse>(`${this.appService.API}/registration/sendResetPasswordMail/${email}/${this.appService.serviceId}`)
  }

  getAuthContacts(page: number): Observable<{
    users: User[];
    counters: { general: number; invitations: number };
  }> {
    return this.http
      .get<{ users: User[]; counters: { general: number; invitations: number } }>(
        `${this.appService.API}/relationship/my/${Date.now()}/${page}/10`
      )
      .pipe(
        map((res) => {
          return {
            users: res.users,
            counters: res.counters,
          };
        })
      );
  }

  getAuthContactsRequest(page: number): Observable<User[]> {
    return this.http
      .get<{ users: User[] }>(`${this.appService.API}/relationship/invitations/FR/${page}/10`)
      .pipe(
        map((res) => {
          return res.users;
        })
      );
  }

  getProfileWithoutRelationship(
    id: number,
    /* country: string,
    page: number = 1,
    offset: number = 10 */
  ): Observable<User> {
    
    return this.http
      .get<{ relationships: string }>(`${this.appService.API}/relationship/status/${id}`)
      .pipe(
        mergeMap(() => {
          //const type = res.relationships === 'friend' ? 'private' : 'public';
          return this.getProfile(id);
        }),
        catchError((error) => {
          console.error(error)
          return throwError(() => error);
        }),
      );
  }

  getProfile(id: number): Observable<User> {
    console.log('getProfile')
    const str = id.toString()
    
    return this.http
      .get<{ user: User; talking_id?: number }>(`${this.appService.API}/api/user/get/${str}/fr`)
      .pipe(
        map((res) => {
          res.user.talking_id = res.talking_id || undefined
          return res.user
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
      );
  }

  dveHash(pass: string): string {
    const salt = MD5(SHA1('AN65Bef2sW6n').toString().substring(3, 27)).toString()
    
    const ret = SHA1(salt.substring(0, 10) + pass + salt.substring(10, 32)).toString()
    return ret;
  }

  // A supprimer : uniquement pour les tests !!! 
  validateQuizz(){
    const body = {
      quizz_id: 28,
      answers: [321,323,325,327,329,331]
    }

    return this.http.post(`${this.appService.API}/api/quizz/answers`, body)
    .pipe(
      map(res=> {
        console.log(res)
      })
    )
  }
  // A supprimer : uniquement pour les tests !!! 
}
