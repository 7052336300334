<section class="profile-stories">
    <div class="profile">
      @if(profile){
        <div class="user-information mobile">
          <div class="avatar-container">
            <img [src]="profile.main_photo?.url" alt="">
          </div>
          <div class="info">
            <h3>{{profile.firstname}}</h3>
            <p>{{userAge}} years - {{profile.city?.name}}</p>
            <div class="searching-container">
                <img src="assets/icons/icon_searching_for.png" alt="" class="searching-icon">
                {{profile.attraction | titlecase}}
            </div>
          </div>
        </div>
      }
    </div>

    <div class="stories-container" #storiesContainer>
        @if(!stories.length){
          @for(i of [].constructor(4); track i){
            <div class="story-container no-stories">
              <img src="../../../assets/icons/icon_gallery.svg" alt="" class="icon-gallery">
            </div>
          }
        } @else {
          @for(s of stories; track s.id){
            <div class="story-container"
              [ngStyle]="getStyles()"
            >
                <app-story [story]="s" [user]="profile"></app-story>
            </div>
          }
        }
    </div>

    @if(!stories.length){
      <h1 class="no-stories">{{profile?.sex === 'man' ? 'He' : 'She'}} hasn't published any stories yet.</h1>
    }
</section>