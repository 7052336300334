
<section class="images-holder" #holder>
    @for(item of storyImages; track item; let i = $index){
        @if(item.src){
            <div class="story-image images-holder-item" [ngStyle]="getStyles()">
                <div class="delete-container">
                  <button type="button" (click)="removeImageFromStory(i)">
                    <span class="material-symbols-rounded delete" >
                      delete
                    </span>
                  </button>
                    
                </div>
                <button type="button" (click)="displayImage(item, i)">
                  <img [src]="item.src" alt="">
                </button>
            </div>
        } @else {
            <button [disabled]="item.disabled" class="no-photo images-holder-item" [ngStyle]="getStyles()" (click)="openFileInput(i)">
                <span class="material-symbols-rounded add">
                    add
                </span>
            </button>

            <div style="display: none;">
                <input 
                type="file"
                accept="image/png, image/jpeg" 
                #fileUpload 
                class="file-upload" 
                (change)="addImage($event.target, i)"
              >
            </div>
        }
    }
           
    <button class="submit" (click)="onSubmit()">
        <span class="material-symbols-rounded check">
            check
        </span>
    </button>
</section>
