import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Dialog } from '@angular/cdk/dialog';

import { AuthService } from '../../../auth.service';

import { GoBackButtonComponent } from '../../../shared/go-back-button/go-back-button.component';
import { DeleteAccountDialogComponent } from '../../../dialog/delete-account-dialog/delete-account-dialog.component';
import { ErrorDialogComponent } from '../../../dialog/error-dialog/error-dialog.component';

import { ClickAnywhereDirective } from '../../../click-anywhere.directive';

@Component({
  selector: 'app-delete-account',
  standalone: true,
  imports: [GoBackButtonComponent, ErrorDialogComponent, ClickAnywhereDirective],
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.scss'
})
export class DeleteAccountComponent {
  @ViewChild('inputPassword') inputPassword!: ElementRef

  dialog = inject(Dialog);
  showPassword = false
  showPortal = false
  error = false
  errorMessage: string | null = null
  errorTitle: string | null = null
  showErrorDialog = false
  currentInputValue = ''

  constructor(
    private location: Location,
    private authService: AuthService,
    private router: Router
  ){}

  showHidePassword() {
    this.showPassword = !this.showPassword
  }

  openDeleteDialog() {
    console.log('openDeleteDialog')
    this.dialog.open(DeleteAccountDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    })
    
    setTimeout(() => {
      this.dialog.closeAll()
      this.authService.remove('token')
      this.router.navigate(['/login'])
    }, 3000)
  }

  onSubmit(){
    const password = this.inputPassword.nativeElement.value

    this.authService.deleteAccount(password).subscribe({
      next: (res) => {
        if(res.error){
          this.error = true
          this.showErrorDialog = true
          this.errorTitle = 'Sorry'
          this.errorMessage = res.message
        } else {
          this.openDeleteDialog()
        }
      }, 
    })
  }

  getValue(e: Event){
    const inputTarget = e.target as HTMLInputElement
    this.currentInputValue = inputTarget.value
  }

  getClickNature(clickNature: string){
    if(clickNature === 'anywhere' && this.showErrorDialog === true){
      this.showErrorDialog = false
    }
  }

  goBack(){
    this.location.back()
  }
}
