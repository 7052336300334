<div class="add-story-dialog">
  <div class="content">
    @if(storyImages.length === 0){
    <button type="button" class="close-btn" (click)="closeDialog()">
      <span class="material-symbols-rounded close"> close </span>
    </button>

    <img
      src="assets/icons/icon_gallery.svg"
      alt="No image"
      class="icon_gallery_pink"
    />
    <h3>Add a new story</h3>

    <div
      class="drop-zone"
      id="drop_zone"
      (drop)="drop($event)"
      (dragover)="allowDrop($event)"
    >
      <p>Drag photos or videos here.</p>
    </div>

    <input
      type="file"
      accept="image/png, image/jpeg"
      #fileUpload
      style="opacity: 0"
      class="file-upload"
      (change)="onPhotoOrVideoInputChange($event)"
    />

    <button class="submit button" (click)="openFileInput()">
      Select from your computer
    </button>
  }
    <!-- } @else {
    <app-create-story
      [homeStoryImages]="storyImages"
    ></app-create-story>
    } -->
  </div>
</div>