// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API: 'https://api-pp.goswipi.com/api',
  //chat: 'dating-chat-pp.dvetech.fr',
  chat: 'api.staging.dating.dv-content.io/chat',
  country: 'en',
  gtm: 'dev',
  LP: 'https://nautilus-dev.contactdve.com/pwzg/service_abo?il_test=wzg_test&il_bpservice=',
  serviceWorker: false,
  HOST: 'k7c2va5hr5dglaorxabwrk4bd4.appsync-api.eu-west-1.amazonaws.com',
  REGION: 'eu-west-1',
  PATH: '/graphql',
  ENDPOINT: 'https://chj3l25lrjexve5ox5x3d33ozu.appsync-api.eu-west-1.amazonaws.com/graphql',
  key: 'da2-rpuasrg2r5chbkele67znjt4fm',
  url: 'https://www.woozgo.fr',
  serviceName: 'swipi',
  localDomain: 'http://localhost:4200/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

