import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { Talk } from '../shared/interfaces/talk';
import { User } from '../shared/interfaces/user';
import { AuthService } from '../auth.service';
import { Message } from '../shared/interfaces/message';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private showConversation$ = new BehaviorSubject<number | null>(null);
  openConversation = new BehaviorSubject<boolean>(false)
  talkingId = new BehaviorSubject<number | null | undefined>(null)
  toggleSidenav = new BehaviorSubject<boolean>(false)
  componentDisplayed = new BehaviorSubject<string>('')

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private authService: AuthService
  ) {}

  set showConversation(value: number | null) {
    this.showConversation$.next(value);
  }

  getShowConversation(): Observable<number | null> {
    return this.showConversation$.asObservable();
  }

  createConversation(id: number): Observable<number> {
    const body = {
      related_user_id: id,
    };
    return this.http.post<{ talking: { id: number } }>(`${this.appService.API}/api/talking`, body).pipe(
      map((res) => {
        return res.talking.id;
      })
    );
  }

  getConversations(): Observable<Talk[]> {
    return this.http.get<{ talkings: Talk[] }>(`${this.appService.API}/api/talking/1/10`).pipe(
      map((res) => {
        res.talkings.map((t) => {
          if (t.last_message) {
            t.last_message.created_at *= 1000;
          }
        });
        return res.talkings;
      })
    );
  }

  // setMessage(id: number, message: string): Observable<any> {

  /* {
"code": 200,
"talking_id": 297894,
"user_id": 211873,
"message":{
"id": 377955,
"text": "Hi ! "
},
"type": "message",
"timestamp": 1736760753
} */
  //   const body = {
  //     related_user_id: id,
  //     content: message,
  //   };

  //   return this.http.post<any>(`${this.appService.API}/api/message`, body).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  // setPhotoMessage(id: number, b64: string, message: string): Observable<any> {
  //   const body = {
  //     photo_b64: b64,
  //     type: 'message',
  //     talking_id: id,
  //     content: message,
  //   };

  //   return this.http.post<any>(`${this.appService.API}/api/photo`, body).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  getMessages( id: number, lastmessageid: number | null): Observable<{ 
    messages: Message[]; 
    room: string; 
    user: User; 
    silent: boolean; 
    talking: { 
      user: { id: number }; 
      related_user: { id: number }
    }
  }> {
    const lastmessage = lastmessageid ? `/${lastmessageid}` : '';

    let userId: number
    this.authService.user.subscribe(res => {
      if(!res) return 
      userId = res?.id
    })

    return this.http
      .get<{
        messages: Message[];
        room_token: string;
        warning_silent_talking: boolean;
        talking: { user: { id: number }; related_user: { id: number } };
      }>(`${this.appService.API}/api/talking/messages/${id}/10${lastmessage}`)
      .pipe(
        mergeMap(({ messages, room_token, talking, warning_silent_talking }) => {
          return this.authService
            .getProfile(
              talking.user.id === userId
                ? talking.related_user.id
                : talking.user.id
            )
            .pipe(
              map((res) => {
                messages.map((m) => (m.created_at *= 1000));
                
                return {
                  messages,
                  room: room_token,
                  user: res,
                  talking: talking,
                  silent: warning_silent_talking,
                };
              })
            );
        })
      );
  }

  setAsRead(talk: number, message: number): Observable<void>{
    console.log(talk, message)
    return of()
  }
  // setAsRead(talk: number, message: number): Observable<any> {
  //   const body = { last_message_id: message.toString(), talking_id: talk.toString() };

  //   return this.http.put(`${this.appService.API}/api/message/read`, body).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  changeSilentTalking(talkingId: number, state: string): Observable<void>{
    console.log(talkingId, state)
    return of()
  }
  // changeSilentTalking(talkingId: number, state: string): Observable<any> {
  //   const body = { talking_id: talkingId.toString(), silent: state };

  //   return this.http.put(`${this.appService.API}/api/talking`, body).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  deleteTalking(id: number): Observable<void>{
    console.log(id)
    return of()
  }
  // deleteTalking(id: number): Observable<any> {
  //   return this.http.delete(`${this.appService.API}/api/talking?talking_id=${id}`).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  setSubscribe(subscriptionId: string) {
    const body = { subscription_id: subscriptionId };

    return this.http.post(`${this.appService.API}/billing/purchasing`, body).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
