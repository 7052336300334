import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-unmatch-dialog',
  standalone: true,
  imports: [],
  templateUrl: './unmatch-dialog.component.html',
  styleUrl: './unmatch-dialog.component.scss'
})
export class UnmatchDialogComponent {
  // create reference to dialog and pass the data type to pass to parent component when dialog is closed (boolean) to trigger save or cancel
  dialogRef = inject<DialogRef<'unmatch' | 'block' | 'report'>>(DialogRef<string>);
  data = inject(DIALOG_DATA);
}
