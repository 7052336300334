import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-info-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './info-dialog.component.html',
  styleUrl: './info-dialog.component.scss'
})
export class InfoDialogComponent implements OnChanges {
  @Input() infoDialogType!: string
  @Output() closeInfoDialog = new EventEmitter<boolean>()

  title = ''
  description = ''
  iconSrc = ''
  buttonText = ''

  ngOnChanges(){
    console.log(this.infoDialogType)

    this.title = this.infoDialogType === 'contactProfile' ? `Unlimited text messages` : `Unlimited likes`
    this.description = this.infoDialogType === 'contactProfile' ? 
      `Activate your premium account and like on unlimited all profiles of your choice!` : 
      `Activate your premium account and send on unlimited direct messages to all profiles of your choice!`
    this.iconSrc = this.infoDialogType === 'contactProfile' ? 'assets/icons/icon_message.svg' : 'assets/icons/icon_love_32.svg'
    this.buttonText = 'Discover'
  }

  closeDialog(){
    this.closeInfoDialog.emit(true)
  }
}
