import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { DOCUMENT } from '@angular/common';

import { AppService } from './app.service';

import { Story } from './shared/interfaces/story';
import { PhotoProfile } from './shared/interfaces/photo-profile';
import { StoryImage } from './shared/interfaces/story-image';
import { User } from './shared/interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class StoriesService {
  private localStorage: Storage | null = null

  tempStoryFiles: StoryImage[] = []
  storyId!: number
  
  constructor(
    private http: HttpClient,
    private appService: AppService,
    @Inject(DOCUMENT) private document: Document,
  ) { 
    this.localStorage = this.document.defaultView?.localStorage || null
  }

  get(key: string): string {
    return this.localStorage?.getItem(key) || ''
  }

  set(key: string, value: string): void {
    this.localStorage?.setItem(key, value)
  }

  getFakeStories(): Observable<Story[]> {
    return this.http.get<Story[]>('./assets/stories.json').pipe(map((res) => {
      return res
    }))
  }

  createStory(): Observable<number>{
    console.log('createStory')

    const body = {}

    return this.http.post<{ story: { id: number } }>(`${this.appService.API}/api/story`, body).pipe(
      map(res => {
        return res.story.id;
      }),
      catchError((error) => {
        return throwError(() => error)
      }),
    )
  }

  addImageToStory(img: string, storyId: number): Observable<number> {
    const body = {
      photo_b64: img,
      story_id: storyId.toString(),
      type: 'story',
    }

    return this.http.post<{photo: {id: number}}>(`${this.appService.API}/api/photo`, body).pipe(
      map(res => {
        return res.photo.id
      }),
      catchError((error) => {
        return throwError(() => error)
      }),
    )
  }

  publishStory(storyId: number | null): Observable<number | void> {
    if(!storyId) return of()

    const body = {
      story_id: storyId.toString(),
    }

    return this.http.put<{story: {id: number}}>(`${this.appService.API}/api/story`, body)
      .pipe(
        map(res => {
          return res.story.id
        }),
        catchError((error) => {
          return throwError(() => error)
        }),
      )
  }

  getPhotos(page: number, offset: number): Observable<PhotoProfile[]> {
    return this.http
      .get<{ photos: PhotoProfile[] }>(`${this.appService.API}/photo/criterias/${page}/${offset}`)
      .pipe(
        map(res => {
          res.photos.map((p) => (p.created_at *= 1000))
          return res.photos
        }),
        catchError(error => {
          return throwError(() => error)
        })
      )
  }

  getStories(page: number, offset: number): Observable<{ stories: Story[], total: number}>{
    return this.http.get<{ stories: Story[]; last_stories: Story[] ; total: number}>(`${this.appService.API}/api/story/criterias/${page}/${offset}`)
    .pipe(
      map(res => {
        res.stories.map((s) => {
          if(!s.created_at) return
          s.created_at *= 1000
        })
        if(res.last_stories){
          res.last_stories.map((ls) => {
            if(!ls.created_at) return
            ls.created_at *= 1000
          })
        }

        const stories = res.stories

        if(res.last_stories && res.stories.length < 10){ 
          res.last_stories.forEach(s => {
            if(stories.length < 10){
              stories.push(s)
            }
          })
        }

        // if(res.last_stories.length > 0) {
        //   console.log('res.last_stories.length > 0')
        //   //res.stories.push({ id: null, likes: null, photos: [], type: 'text' })
        //   res.stories = res.stories.concat(res.last_stories)
        // }
        return { stories: stories, total: res.total}
      }),
      catchError(error => {
        return throwError(() => error)
      })
    )
  }

  getStoriesByUser(page: number, offset: number, id?: number): Observable<Story[]> {
    let user!: User
    if(!id) {
      user = JSON.parse(this.get('user'))
    }

    return this.http.get<{ stories: Story[]; last_stories?: Story[] }>(`${this.appService.API}/api/story/user/${id ? id : user.id}/${page}/${offset}`)
    .pipe(
      map(res => {
        res.stories.map((s) => {
          if(!s.created_at) return
          s.created_at *= 1000
        })

        if(res.last_stories){
          res.last_stories.map((ls) => {
            if(!ls.created_at) return
            ls.created_at *= 1000
          })
  
          if (res.last_stories.length > 0) {
            res.stories.push({ id: null, likes: null, photos: [], type: 'text' })
            res.stories = res.stories.concat(res.last_stories)
          }
        }
        
        return res.stories
      }),
      catchError(error => {
        return throwError(() => error)
      })
    )
  }

  getOneStory(id: number): Observable<{ story: Story }> {
    return this.http.get<{ story: Story }>(`${this.appService.API}/story/get/${id}`).pipe(
      map(res => {
        return res
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  deleteStory(type: string, id: number): Observable<{code: number}> {
    console.log('deleteStory')
    return this.http.delete<{code: number}>(`${this.appService.API}/api/story?story_id=${id}`).pipe(
      map(res => {
        return res
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  addCrushOnStory(storyId: number, type: string): Observable<number>{
    const body = {
      id: storyId,
      type: type
    }

    return this.http.post(`${this.appService.API}/api/crush/like`, body).pipe(
      map(res => {
        console.log(res)
        return storyId
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  removeCrushOnStory(storyId: number, type: string): Observable<number>{
    return this.http.delete(`${this.appService.API}/api/crush/dislike?id=${storyId}&type=${type}`).pipe(
      map(res => {
        console.log(res)
        return storyId
      }),
      catchError((error) => {
        return throwError(() => error)
      })
    )
  }

  getCrushesByStory(storyId: number, type: string): Observable<User[]>{
    return this.http.get<{users: User[]}>(`${this.appService.API}/api/crush/content/${type}/${storyId}/1/10`).pipe(
      map(res => {
        console.log(res)
        return res.users
      })
    )
  }
}
