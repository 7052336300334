import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, Self, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

import { AppService } from '../../app.service';
import { StoryService } from '../story.service';

import { InitialStoryPhotoArray, PhotoObject } from './story-photos';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-images-holder',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './images-holder.component.html',
  styleUrl: './images-holder.component.scss'
})
export class ImagesHolderComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('fileUpload') fileUpload!: ElementRef
  @ViewChild('saveDialog') saveDialog!: ElementRef
  @ViewChild('holder') holder!: HTMLDivElement
  @HostListener('window:resize', ['$event'])
  @Output() currentImageDisplayed = new EventEmitter<{image: PhotoObject, index: number}>()
  @Output() removeImage = new EventEmitter<boolean>()
  @Output() openDialog = new EventEmitter<{type: string, imageToSave?: boolean}>()

  public getScreenWidth!: number;
  public getScreenHeight!: number;
  
  mobileScreenRatio!: number
  storyImages = InitialStoryPhotoArray
  desktopDevice = false
  storyWidth = 0
  storyImagesFromService: PhotoObject[] = []
  storyImagesSubscription!: Subscription

  get height(): number {
    return this.element.nativeElement.offsetHeight;
  }
  get width(): number {
    return this.element.nativeElement.offsetWidth;
  }

  constructor(
    private appService: AppService,
    private storyService: StoryService,
    private router: Router,

    @Self() private element: ElementRef
  ){}

  ngOnInit(){
    this.storyImagesSubscription = this.storyService.storyImages.subscribe(images => {
      this.storyImagesFromService = images

      images.forEach((img, index) => {
        this.storyImages[index].src = img.src
      })
    })

    if(!this.appService.desktopDevice){
      this.mobileScreenRatio = window.innerHeight / window.innerWidth
    } 
    this.desktopDevice = this.appService.desktopDevice
  }

  ngAfterViewChecked(){
    if(!this.desktopDevice){
      const screenWidth = window.screen.width
      const submitButtonWidth = 64
      const gapWidth = 4
      const outlineWidth = 3 * 2
      this.storyWidth = (screenWidth - (submitButtonWidth + 16 + gapWidth)) / 5 - (gapWidth + outlineWidth)
    } else {
      const popupWidth = 600
      const submitButtonWidth = 96
      const gapWidth = 12
      const outlineWidth = 3 * 2
      this.storyWidth = (popupWidth - (submitButtonWidth + 48 + gapWidth)) / 5 - (gapWidth + outlineWidth)
    } 
  }

  getStyles(){
    return {
      width: `${this.storyWidth}px`,
      height: `${this.storyWidth * 1.4}px`
    }
  }

  onSubmit(){
    console.log(this.storyImages)
    const imagesToSave = this.storyImages.filter(i => !!i.src && !i.saved)
    if(imagesToSave.length > 0) {
      this.openDialog.emit({type: "publish", imageToSave: true})
    } else {
      this.openDialog.emit({type: 'publish'})
    }
  }

  displayImage(image: PhotoObject, index: number){
    this.currentImageDisplayed.emit({image, index})
  }

 //if the previous image is saved, open the file input
  openFileInput(i: number){
    if(i === 0 || this.storyImages[i-1].saved){
      this.openDialog.emit({type: 'save', imageToSave: true})
      this.fileUpload.nativeElement.click()
    } else {
      this.openDialog.emit({type: 'save', imageToSave: true})
    }
  }

  //adds image to service and displays it
  addImage(target: EventTarget | null, index: number) {
    const fileInput = target as HTMLInputElement
    if(!fileInput) return
    if(!fileInput.files) return
    const imageFile = fileInput.files[0]
    const imageFileBlob = URL.createObjectURL(imageFile) 

    this.storyImages[index].src = imageFileBlob
    this.storyImages[index+1].disabled = false
    this.storyService.storyImages.next(this.storyImages)
    this.displayImage(this.storyImages[index], index)
  }

  //removes image from the story and displays the next image if there is one, setting the current image to null
  removeImageFromStory(index: number){
    let i = index
    const max = 4

    if(i === 0){
      this.storyImages[i].src = null
      this.storyImages[i].saved = false
      this.storyImages[i+1].disabled = true
      this.storyService.storyImages.next(this.storyImages)
      this.displayImage(this.storyImages[i], i)
      this.removeImage.next(true)

      const images = this.storyImagesFromService.filter(i => !!i.src)
      if(!images.length) this.router.navigate(['/home'])

      return
    }
    
    while(i < max){
      if(this.storyImages[i+1]?.src){ 
        this.storyImages[i].src = this.storyImages[i+1].src  
      } else {
        this.storyImages[i+1].disabled = true
        this.storyImages[i].src = null
        this.storyImages[i].saved = false
        this.storyService.storyImages.next(this.storyImages)
        this.removeImage.next(true)
        this.displayImage(this.storyImages[i-1], i-1)
        break
      }

      i++  
    }
  }

  ngOnDestroy(){
    this.storyImagesSubscription.unsubscribe()
  }
}

