import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import dayjs from 'dayjs';

import { ProfileService } from '../../profile/profile.service';
import { AuthService } from '../../auth.service';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-match',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './match.component.html',
  styleUrl: './match.component.scss'
})
export class MatchComponent implements OnInit {
  @Input() profile!: User
  
  newMatch = false
  loggedIn = false

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
  ){}

  ngOnInit(){
    this.authService.getProfile(this.profile.id).subscribe(res => {
      this.profileService.profile.next(res)

      const profileLastConnectionDate = dayjs(this.profile.connected_at)
      const today = dayjs(new Date())
      const timeDiff = today.diff(profileLastConnectionDate, 'day')

      if(timeDiff <= 1) this.loggedIn = true

      const matchDate = dayjs(this.profile.updated_at)
      const matchTimeDiff = today.diff(matchDate, 'day')

      if(matchTimeDiff <= 1) this.newMatch = true
    })
  }

  goToProfile(id: number){
    this.router.navigate(['/home/my-matches/profile/' + id + '/space'])
  }
}
