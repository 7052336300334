import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

import { ChatService } from './chat.service';
import { UserTinyService } from './user-tiny/user-tiny.service';
import { AppService } from '../app.service';

import { UserTinyComponent } from './user-tiny/user-tiny.component';

import { Talk } from '../shared/interfaces/talk';
import { User } from '../shared/interfaces/user';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [RouterModule, UserTinyComponent, MatSidenav, MatSidenavModule, CommonModule],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild('conversationSidenav') conversationSidenav!: MatSidenav

  hideHeader = false;
  showConversation = false;
  talkingWith: User | null = null;
  talks: Talk[] = [];
  conversationId = null;
  loading = true;
  talkUserId: number | null = null;
  talkId!: number | null | undefined
  talk!: Talk | undefined
  desktopDevice = false
  conversationSidenavToggled = false
  toggleSubscription!: Subscription

  constructor(
    private chatService: ChatService,
    private userTinyService: UserTinyService,
    private appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute
    // private chatService: ChatService,
    // private serviceService: ServiceService,
    // private userTinyService: UserTinyService
  ) {}

  ngOnInit() {
    this.chatService.getConversations().subscribe((res) => {
      console.log(res)

      /* 
        0:
        id:297892
        last_message: 
          content: "Hello there"
          created_at: 1736259111000
          id:377953
          user_id: 212084
        myMessage: false
        new_messages: 1
        readed: false
        silent: 0
        user: 
          birthdate: "1990-02-13"
          city_id: 1
          connected_at: "2025-01-03 16:05:58"
          country_id: 1
          firstname: "woman_test_relationship"
          id: 212084
          is_coach: false
          photo: "https://assets.staging.dating.dv-content.io/users/212084/profile/crop/67780afe12b56_1735920382.jpeg"		
          reported: false
          sex: "woman"
          type: null
        warning_silent_talking: false
      */
      this.talks = res;
      this.loading = false;
      
      this.talks.map((t) => {
        const text =
          t.last_message && t.last_message.content
            ? decodeURIComponent(t.last_message.content).slice(0, 18) + '...'
            : null;

        t.myMessage = t.last_message ? t.last_message.user_id !== t.user.id : false;
        t.userDatas = {
          user: {
            firstname: t.user.firstname,
            type: 'friend',
            id: t.user.id,
            gendre: t.user.sex,
            photo: t.user.photo,
          },
          content: text,
          photos: t.last_message ? !!t.last_message.photos : false,
          time: t.last_message ? t.last_message.created_at : null,
          actions: {
            block: true,
            reportUser: true,
            remove: true,
            deleteTalking: true,
          },
          talking: t.id,
        };
      });

      if (window.innerWidth >= 1024 && window.innerHeight >= 600) this.appService.desktopDevice = true
      this.desktopDevice = this.appService.desktopDevice

      if(this.desktopDevice) {
        this.conversationSidenav.open()
        this.chatService.toggleSidenav.next(true)
      }

      this.toggleSubscription = this.chatService.toggleSidenav.subscribe((res: boolean) => {
        this.conversationSidenavToggled = res

        if(this.conversationSidenav) {
          if(res){
            this.conversationSidenav.open()
          } else {
            this.conversationSidenav.close()
          }
        }
      })
    });
    
    this.chatService.getShowConversation().subscribe((ret) => {
      console.log(ret)
      this.loading = !!ret;
      this.showConversation = !!ret;
      this.talkUserId = ret;
      //this.serviceService.hideFooter(!!ret);
    });

    this.userTinyService.success.subscribe((res) => {
      if (res.type === 'accept') {
        const silent = this.talks.find((t) => t.user.id === res.user);

        if(!silent) return

        silent.warning_silent_talking = false;
      }

      if (res.type === 'delete') {
        this.talks = this.talks.filter((t) => t.user.id !== res.user);
      }
    });
  }

  chatShow() {
    this.showConversation = true;
  }

  setAsRead(talk: Talk): void {
    if (!talk.readed) {
      talk.readed = true;
      this.chatService.setAsRead(talk.id, talk.last_message.id).subscribe();
    }
  }

  getTalkSelected(e: number | null | undefined) {
    this.talkId = e
    console.log(this.talkId)

    this.talk = this.talks.find(t => t.id === this.talkId)
    this.conversationSidenav.open()
    this.showConversation = true
  }

  // onSwipeLeft(user) {
  //   user.actions = true;
  // }

  // onSwipeRight(user) {
  //   user.actions = false;
  // }

  // showHideHeader($event) {
  //   this.hideHeader = $event;
  // }

  ngOnDestroy(){
    this.toggleSubscription.unsubscribe()
  }
}
