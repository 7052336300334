import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Subscription, switchMap } from 'rxjs';

import { AppService } from '../../app.service';
import { AuthService } from '../../auth.service';
import { MySpaceService } from '../../my-space/my-space.service';

import { LoaderComponent } from '../loader/loader.component';
import { GoBackButtonComponent } from '../go-back-button/go-back-button.component';
import { FooterComponent } from '../../footer/footer.component';
import { TopButtonComponent } from '../top-button/top-button.component';

import { Hobby } from '../interfaces/hobby';
import { User } from '../interfaces/user';

function transformData(hobbies: Hobby[]) {
  const hobbiesArr = [...hobbies]

  hobbiesArr.forEach(hobby => {
    const subLabelArr = hobby.sub_label?.split(',')
    let formattedSubLabelStr = ''
    
    subLabelArr?.forEach((subLabel, index) => {
      if(index === subLabelArr.length - 1) {
        formattedSubLabelStr += subLabel
      } else {
        formattedSubLabelStr += subLabel + ', '
      }
    })

    hobby.sub_label = formattedSubLabelStr
  })
  
  return hobbiesArr
}

@Component({
  selector: 'app-my-hobbies',
  standalone: true,
  imports: [LoaderComponent, FormsModule, ReactiveFormsModule, GoBackButtonComponent, FooterComponent, TopButtonComponent],
  templateUrl: './my-hobbies.component.html',
  styleUrl: './my-hobbies.component.scss'
})
export class MyHobbiesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('top') top!: ElementRef
  
  loading = false 
  hobbies: Hobby[] = []
  checked = false
  checkedId!: number
  loaderSubscription!: Subscription
  user!: User | null
  userHobbies: Hobby[] = []

  constructor(
    private appService: AppService,
    private http: HttpClient,
    private location: Location,
    private authService: AuthService,
    private mySpaceService: MySpaceService
  ){}

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    this.authService.authMe$.subscribe(res => {
      this.user = res

      if(!this.user) return

      this.mySpaceService.getHobbiesByUser(this.user.id).subscribe(res => {
        this.userHobbies = res
      
        if(!this.hobbies.length){
          this.mySpaceService.getHobbiesList().subscribe(res => {
            this.hobbies = transformData(res)
  
            this.userHobbies.forEach(userHobby => {
              this.hobbies.forEach(h => {
                if(userHobby.id === h.id) {
                  h.selected = true
                }
              })
            })
          })
        } 
      })
    })
  }

  ngAfterViewInit(){
    this.top.nativeElement.scrollIntoView()
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  clickHobby(hobby: Hobby){
    this.hobbies.forEach(h => {
      if(h === hobby){
        h.selected = !h.selected
      }
    })
    
    this.saveHobbies()
  }

  goBack(){
    this.location.back()
  }

  saveHobbies(){
    if(!this.user) return
    
    const selectedHobbies = this.hobbies.filter(h => h.selected)
    const ids: number[] = []

    selectedHobbies.forEach(h => {
      ids.push(h.id)
    })

    const idsStr = ids.join(',')
    this.mySpaceService.updateHobbiesByUser(this.user.id, idsStr).pipe(
      switchMap(() => this.mySpaceService.getHobbiesByUser(this.user!.id))
    ).subscribe({
      next: console.log,
      error: err => console.error(err.message),
      complete: () => console.log("service call 1 used to create service call 2, which is complete")
    })
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
