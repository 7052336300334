<div class="user-tiny" >
  <div class="content">
    <app-go-back-button (click)="closeTalk()"></app-go-back-button>

    @if(relatedUser){
      <div class="photo-container" >
        <button type="button" class="user-tiny-photo" (click)="viewProfile()">
          @if(relatedUser.main_photo){
            <img
              [src]="relatedUser.main_photo.url"
              (load)="imageLoaded()"
              (error)="imageError()"
              [class.ready]="datas?.user?.loaded && !datas?.user?.error"
              alt=""
            />
          } @else {
            <div class="no-photo">
              <img src="assets/icons/icon_user_96X96.png" alt="">
            </div>
          }
        </button>
      </div>
    } @else if(avatar){
      <div class="photo-container" >
        <button type="button" class="user-tiny-photo" (click)="viewProfile()">
          @if(datas?.user?.photo){
            @if(!datas?.user?.loaded && !datas?.user?.error){
              <div class="loading">
                update icon
                <!-- <mat-icon svgIcon="update"></mat-icon> -->
              </div>
            }
            
            @if(datas?.user?.error){
              <div class="error">404</div>
            }
            
            <img
              [src]="datas?.user?.photo"
              (load)="imageLoaded()"
              (error)="imageError()"
              [class.ready]="datas?.user?.loaded && !datas?.user?.error"
              alt=""
            />
          } @else {
            <div class="no-photo">
              <img src="assets/icons/icon_user_96X96.png" alt="">
            </div>
          }
        </button>
      </div>
    }
    
    <button 
      type="button" 
      class="user-tiny-data" 
      [class.avatar]="avatar" 
      [routerLink]="['conversation/' + datas?.talking]"
      (click)="openTalk()"
    >
      @if(relatedUser){
        <h5 class="user-tiny-data-name">
          {{ relatedUser.firstname }}
  
          <!-- @if(datas?.content && datas?.content === 'ProfilPhotoChanged'){
            <span class="user-tiny-data-name-change">a changé sa photo de profil</span>
          } -->
        </h5>
      } @else if(!datas?.me){
        <h5 class="user-tiny-data-name">
          {{ datas?.user?.firstname }}
  
          @if(datas?.content && datas?.content === 'ProfilPhotoChanged'){
            <span class="user-tiny-data-name-change">a changé sa photo de profil</span>
          }
        </h5>
      } @else {
        <span class="user-tiny-data-name mine">Your content</span>
      }
      
      @if(datas?.content && datas?.content !== 'ProfilPhotoChanged'){
        <span
          class="user-tiny-data-message"
          [innerHTML]="datas?.content"
        >
        </span>
      }
      
      @if(datas?.photos){
        <span class="user-tiny-data-photo">
          photo icon
          <!-- <mat-icon svgIcon="photo"></mat-icon> -->
        </span>
      }
  
      <div class="user-tiny-time-date">
        @if(datas?.today){
          <ng-container>Today</ng-container>
        }
        
        @if(datas?.yesterday){
          <ng-container>Yesterday</ng-container>
        }
        
        @if(!datas?.yesterday && !datas?.today){
          <ng-container>{{ datas?.time | date: 'd MMM yyy' }}</ng-container>
        }
      </div>
    </button>

    <button type="button" class="contextual-menu closed show" #contextualMenuClosed>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </button>
  
    <div class="user-tiny-actions" [class.display]="actions">
      @if(!updating){
        <!-- <button class="main" (click)="showHideActions()">
          menu icon
          <mat-icon svgIcon="menu"></mat-icon>
        </button> -->
      } @else {
        <div class="loading">
          update icon
          <!-- <mat-icon svgIcon="update"></mat-icon> -->
        </div>
      }
      
      <div
        class="subactions"
        class="subactions"
        [class.display]="actions"
        [class.vertical]="direction === 'vertical'"
        [class.horizontal]="direction === 'horizontal'"
        [class.two]="!datas?.actions?.remove"
        [class.three]="datas?.actions?.block"
      >
  
        @if(datas?.actions?.removeStory){
          <button type="button"
            class="subactions-button remove"
            (click)="throwConfirm('remove', 'story')"
          >
            <span class="label">Remove your story</span>
        </button>
        }
        
        @if(datas?.actions?.removePhoto){
          <button type="button"
            class="subactions-button remove"
            (click)="throwConfirm('remove', 'photo')"
          >
            <span class="label">Remove your photo</span>
          </button>
        }
        
        @if(datas?.actions?.removeEvent){
          <button type="button"
            class="subactions-button remove"
            (click)="throwConfirm('remove', 'event')"
          >
            <span class="label">Remove your event</span>
          </button>
        }
        
        @if(datas?.actions?.removeExternalLink){
          <button type="button"
            class="subactions-button remove"
            (click)="throwConfirm('remove', 'externallink')"
          >
            <span class="label">Remove your external link</span>
          </button>
        }
        
        @if(datas?.actions?.agreed){
          <button type="button"
            class="subactions-button agreed"
            (click)="changeRelationship('validated')"
          >
            <span class="label">Agreed</span>
          </button>
        }
  
        @if(datas?.actions?.denied){
          <button type="button"
            class="subactions-button denied"
            (click)="changeRelationship('rejected')"
          >
            <span class="label">Agreed</span>
          </button>
        }
        
        @if(datas?.actions?.block){
          <button type="button" class="subactions-button" (click)="throwConfirm('block')">
            <span class="label">Blocked</span>
          </button>
        }
       
        @if(datas?.actions?.reportUser){
          <button type="button"
            class="subactions-button"
            (click)="throwConfirm('report', 'user')"
          >
            <span class="label">Report</span>
          </button>
        }
  
        @if(datas?.actions?.reportStory){
          <button type="button"
            class="subactions-button"
            (click)="throwConfirm('report', 'story')"
          >
            <span class="label">Report this story</span>
          </button>
        }
        
        @if(datas?.actions?.reportPhoto){
          <button type="button"
            class="subactions-button"
            (click)="throwConfirm('report', 'photo')"
          >
            <span class="label">Report this picture</span>
          </button>
        }
        
        @if(datas?.actions?.reportEvent){
          <button type="button"
            class="subactions-button"
            (click)="throwConfirm('report', 'event')"
          >
            <span class="label">Report this event</span>
          </button>
        }
  
        @if(datas?.actions?.reportExternalLink){
          <button type="button"
            class="subactions-button"
            (click)="throwConfirm('report', 'externallink')"
          >
            <span class="label">Report this link</span>
          </button>
        }
        
        @if(datas?.actions?.deleteTalking){
          <button type="button"
            class="subactions-button"
            (click)="throwConfirm('delete', 'talking')"
          >
            <span class="label">Remove Conversation</span>
          </button>
        }
        
        @if(datas?.actions?.remove){
          <button type="button"
            class="subactions-button"
            (click)="throwConfirm('remove', 'user')"
          >
            <span class="label">Remove User</span>
          </button>
        }
      </div>
    </div>
  </div>
  
  <hr class="separation-line">
</div>

