import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';

@Component({
  selector: 'app-range-input',
  standalone: true,
  imports: [CommonModule, MatSliderModule],
  templateUrl: './range-input.component.html',
  styleUrl: './range-input.component.scss',
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class RangeInputComponent {
  @Output() minValueSelection = new EventEmitter<number>()
  @Output() maxValueSelection = new EventEmitter<number>()
  @Input() ageRange!: {min: number, max: number}

  getMinValue(e: number){
    this.ageRange.min = e
    this.minValueSelection.emit(this.ageRange.min)
  }

  getMaxValue(e: number){
    this.ageRange.max = e
    this.maxValueSelection.emit(this.ageRange.max)
  }
}
