import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';

@Component({
  selector: 'app-conversation-dialog',
  standalone: true,
  imports: [],
  templateUrl: './conversation-dialog.component.html',
  styleUrl: './conversation-dialog.component.scss'
})
export class ConversationDialogComponent {
  // create reference to dialog and pass the data type to pass to parent component when dialog is closed (boolean) to trigger save or cancel
  dialogRef = inject<DialogRef<'block' | 'unmatch' | 'delete' | 'report'>>(DialogRef<string>);
  data = inject(DIALOG_DATA);
}
