<app-go-back-button class="edit-go-back-btn" (click)="goBack()"></app-go-back-button>  

<section class="delete-account" appClickAnywhere (clickNature)="getClickNature($event)">
  <div class="top" #top>
    <h3><span class="regular">My</span> Account</h3>
  </div>

  <h4><span class="regular">Delete</span> My Account</h4>

  <div class="input-holder">
    <button type="button" class="password-btn" [class.showHide]="!showPassword" (click)="showHidePassword()">
      <span class="material-symbols-outlined password-icon">
        visibility
      </span>
    </button>
    <button type="button" class="password-btn" [class.showHide]="showPassword" (click)="showHidePassword()">
      <span class="material-symbols-outlined password-icon">
        visibility_off
      </span>
    </button>

    <input
      id="password"
      formControlName="passwordCtrl"
      placeholder="Password"
      autocapitalize="none"
      #inputPassword
      [type]="showPassword ? 'text': 'password'"
      (input)="getValue($event)"
    />

    <p class="tip">For security reasons, please enter your password to confirm deletion of your Swipi account.</p>
  </div>

  <button
    class="submit"
    type="submit" 
    (click)="onSubmit()"
    [disabled]="currentInputValue.length < 6 || error"
  >
    Delete my account
  </button>

  @if(showPortal){
    <div class="example-portal-outlet">
      <ng-template [cdkPortalOutlet]="componentPortal"></ng-template>
    </div>
  }

  @if(showErrorDialog){
    <app-error-dialog [errorMessage]="errorMessage" [errorTitle]="errorTitle"></app-error-dialog>
  }
</section>
    