<div class="profile" [title]="profile.firstname">
    <button class="main" type="button" (click)="goToProfile(profile.id)">
      <div class="photo-container" [class.logged-in]="profile.loggedIn">
        @if(profile.photo){
          <img [src]="profile.photo" alt="" class="photo">
        } @else {
          <img src="../../../assets/icons/icon_user_96X96.png" alt="" class="no-photo">
        } 
      </div>
      
      <p class="firstname" routerLinkActive="router-link-active" >{{profile.firstname}}</p>
    </button>
    
    <img src="../../../assets/icons/icon_match.svg" alt="" class="icon" [class.show]="newMatch">
</div>