import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppService } from './app.service';

import { Color } from './shared/interfaces/color';
import { Story } from './shared/interfaces/story';
import { NotificationCount } from './shared/interfaces/notification-count';
import { Slider } from './shared/interfaces/slider';
import { Likers } from './shared/interfaces/likers';

export interface GetNotificationsCountResponse {
  messages: { exist: boolean; count: number }
  invitations: { exist: boolean; count: number }
  new_contacts: { exist: boolean; count: number }
  news: { exist: boolean; count: number }
}

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  private footerState$ = new Subject<boolean>()
  private newFeedStory$ = new Subject<{ display: boolean; value: Story | null }>()
  // private newFeedLink$ = new Subject<{ display: boolean; value: any }>()
  // private newFeedEvent$ = new Subject<{ display: boolean; value: any }>()
  private slider$ = new Subject<Slider>()
  private liveTimeStamp = Math.round(Date.now() / 1000)
  private liveNotifications$ = new Subject<NotificationCount>()
  private likers$ = new Subject<Likers>()
  private profil$ = new Subject<boolean>()

  // readonly notificationObservable$: Observable<any>
  // readonly feedObservable$!: Observable<any>

  private storyDate = null
  private photoDate = null

  private stop$ = new Subject<void>()
  private start$ = new Subject<void>()

  private photoLiked$ = new Subject<{ id: number; liked: boolean }>()

  get footerState(): Observable<boolean> {
    return this.footerState$.asObservable()
  }

  hideFooter(value: boolean) {
    this.footerState$.next(value)
  }

  get newFeedStory(): Observable<{ display: boolean; value: Story | null }> {
    return this.newFeedStory$.asObservable()
  }

  setNewFeedStory(value: { display: boolean; value: Story | null }) {
    this.newFeedStory$.next(value)
  }

  // get newFeedEvent(): Observable<{ display: boolean; value: any }> {
  //   return this.newFeedEvent$.asObservable()
  // }

  // setNewFeedEvent(value: { display: boolean; value: any }) {
  //   this.newFeedEvent$.next(value)
  // }

  // get newFeedExternalLink(): Observable<{ display: boolean; value: any }> {
  //   return this.newFeedLink$.asObservable()
  // }

  // setNewFeedExternalLink(value: { display: boolean; value: any }) {
  //   this.newFeedLink$.next(value)
  // }

  get slider(): Observable<Slider> {
    return this.slider$.asObservable()
  }

  setSlider(value: Slider) {
    this.slider$.next(value)
  }

  get liveNotifications(): Observable<NotificationCount> {
    return this.liveNotifications$.asObservable()
  }

  setLikers(value: Likers) {
    this.likers$.next(value)
  }

  get likers(): Observable<Likers> {
    return this.likers$.asObservable()
  }

  set lastStoryDate(value) {
    this.storyDate = value
  }

  get lastStoryDate() {
    return this.storyDate
  }

  set lastPhotoDate(value) {
    this.photoDate = value
  }

  get lastPhotoDate() {
    return this.photoDate
  }

  setLikedPhoto(value: { id: number; liked: boolean }) {
    this.photoLiked$.next(value)
  }

  get likedPhoto(): Observable<{ id: number; liked: boolean }> {
    return this.photoLiked$.asObservable()
  }

  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) {
    /* this.notificationObservable$ = timer(0, 20000).pipe(
      switchMap(() => {
        return this.getNotificationsCount().pipe(
          map(({ invitations, messages, new_contacts, news }) => {
            const notifs = {
              exist: invitations.exist || new_contacts.exist || news.exist,
              count: invitations.count + new_contacts.count + news.count,
            }

            const ret = {
              messages: messages as { exist: boolean; count: number },
              notifs: notifs as { exist: boolean; count: number },
              invitations,
              contacts: new_contacts,
              news,
            }
            this.liveNotifications$.next(ret)
            return ret
          })
        )
      }),
      takeUntil(this.stop$),
      repeatWhen(() => this.start$)
    ) */
  }

  start(): void {
    this.start$.next()
  }

  stop(): void {
    this.stop$.next()
  }

  getColors(): Observable<Color[]> {
    return this.http.get<Color[]>('./assets/colors.json').pipe(map((res) => {
      return res
    }))
  }

  getNotificationsCount(): Observable<GetNotificationsCountResponse> {
    //const now = Date.now()

    return this.http
      .get<GetNotificationsCountResponse>(`${this.appService.API}/notifications/general/${this.liveTimeStamp}/0/fr`)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  getShowHideProfile(): Observable<boolean> {
    return this.profil$.asObservable()
  }

  showHideProfile(value: boolean) {
    this.profil$.next(value)
  }
}
