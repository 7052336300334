<h3>Are you sure you wish to block this user?</h3>

<p class="description">This person will no longer be able to view your profile or contact you.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()">Cancel</button>
  <button class="submit" (click)="dialogRef.close(true)">
    <img src="assets/icons/icon_block.svg" alt="" class="block-icon">
    Block
  </button>
</div>

