import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, map, catchError, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AppService } from '../app.service';

import { Story } from '../shared/interfaces/story';
import { User } from '../shared/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private footerState$ = new Subject<boolean>();

  private storyDate = null;
  private photoDate = null;

  private stop$ = new Subject<void>();
  private start$ = new Subject<void>();

  toggleSidenav = new BehaviorSubject<boolean>(false)
  toggleEncounterSidenav = new BehaviorSubject<boolean>(false)
  pageDisplayed = new BehaviorSubject<string>('home')
  stories: Story[] = []
  story = new BehaviorSubject<Story | null>(null)
  encounters = new BehaviorSubject<User[]>([])
  dialogDisplayed = new BehaviorSubject<boolean>(false)

  private photoLiked$ = new Subject<{ id: number; liked: boolean }>();

  get footerState(): Observable<boolean> {
    return this.footerState$.asObservable();
  }

  hideFooter(value: boolean) {
    this.footerState$.next(value);
  }

  set lastStoryDate(value) {
    this.storyDate = value;
  }

  get lastStoryDate() {
    return this.storyDate;
  }

  set lastPhotoDate(value) {
    this.photoDate = value;
  }

  get lastPhotoDate() {
    return this.photoDate;
  }

  setLikedPhoto(value: { id: number; liked: boolean }) {
    this.photoLiked$.next(value);
  }

  get likedPhoto(): Observable<{ id: number; liked: boolean }> {
    return this.photoLiked$.asObservable();
  }

  constructor(
    private http: HttpClient,
    private appService: AppService,
  ) {}

  createReportBetweenUsers(id: number, targetType: string): Observable<string>{
    const body = {
      target: targetType,
      id: id
    }

    return this.http.post<{message: string}>(`${this.appService.API}/api/reports`, body).pipe(
      map(res => {
        console.log(res)
        
        return res.message
      }),
      catchError((error) => {
        console.error(error)
        return throwError(() => error);
      }),
    )
  }

  updateRelationship(relatedUserId: number, state: string): Observable<string> {
    const body = {
      related_user_id: relatedUserId.toString(),
      state,
    }

    return this.http.post(`${this.appService.API}/api/relationship/easy`, body)
      .pipe(
        map(() => {
          return `User ${relatedUserId} has been successfully ${state}`
        }),
        catchError(error => {
          console.log(error)
          return throwError(() => error)
        })
      )
  }

  deleteRelationship(relatedUserId: number): Observable<string> {
    return this.http.delete(`${this.appService.API}/api/relationship?related_user_id=${relatedUserId}`)
      .pipe(
        map(() => {
          return `User ${relatedUserId} has been successfully deleted`
        }),
        catchError(error => {
          console.log(error)
          return throwError(() => error)
        })
      )
  }
}
