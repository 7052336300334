<h3>Delete this match?</h3>

<p class="description">This person will no longer be able to contact you.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()">
    Cancel
  </button>
  <button class="submit" (click)="dialogRef.close('unmatch')">
    Delete
  </button>
</div>
