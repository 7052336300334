import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserTinyService {
  private confirm$ = new BehaviorSubject<{
    display: boolean;
    value: string | null;
    type: string | null;
    user: {
      name: string | null;
      id: number | null;
      gendre: string | null;
    };
    talking: number | null;
    feedId: number | null;
  }>({
    display: false,
    value: null,
    type: null,
    user: { name: null, id: null, gendre: null },
    talking: null,
    feedId: null,
  });

  private success$ = new Subject<{
    value: string | null;
    type: string;
    user: number;
    talking: number;
    feed: number | null;
  }>();

  get confirm(): Observable<{
    display: boolean;
    value: string | null;
    type: string | null;
    user: { gendre: string | null; name: string | null; id: number | null };
    talking: number | null;
  }> {
    return this.confirm$.asObservable();
  }

  get success(): Observable<{
    type: string;
    user: number;
    talking: number;
    feed: number | null;
    value: string | null;
  }> {
    return this.success$.asObservable();
  }

  throwConfirm(value: {
    display: boolean;
    value: string;
    type: string;
    user: { gendre: string; name: string; id: number };
    talking: number;
    feedId: number;
  }) {
    this.confirm$.next(value);
  }

  throwSuccess(value: {
    value: string | null;
    type: string;
    user: number;
    talking: number;
    feed: number | null;
  }) {
    this.success$.next(value);
  }

  constructor() {}
}
