import { Injectable } from '@angular/core';
import { WebSocketSubject } from 'rxjs/webSocket';
import * as crypto from 'crypto-js';
import { Subject } from 'rxjs';

import { AuthService } from '../auth.service';
import { AppService } from '../app.service';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private api = environment.chat;
  private usertoken: string;
  private key = 'dve-dating-chat-ZgRffh74p7FeQfVD';
  private iv = 'uinbha458750987y';

  private ws$!: WebSocketSubject<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private message$ = new Subject<any>();
  private room: number | string | null = null;
  private closing = false;

  constructor(
    private authService: AuthService, 
    private appService: AppService
  ) {
    this.usertoken = authService.get('token');
  }

  serviceId = Number(this.appService.serviceId.replace('/', ''));
  private ws: WebSocket | null= null;

  get token() {
    const t = crypto.AES.encrypt(this.usertoken, this.key, {
      iv: CryptoJS.enc.Hex.parse(this.iv),
    });

    return t;
  }

  connect(
    talkingId: number,
    roomToken: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    el?: any,
    message?: { userId: number; value: string; photo?: string }
  ) {
    this.room = talkingId;
    const self = el ? el : this;
    this.closing = false;

    if (!self.ws) {
      self.ws = new WebSocket(
        `wss://${this.api}/?serviceId=${self.serviceId}&token=${self.authService.get('token')}`
      );
      self.ws.onopen = () => {
        console.log('ws is open');
        self.ws.send(
          JSON.stringify({
            action: 'joinroom',
            serviceId: self.serviceId,
            room: talkingId,
            token: roomToken,
          })
        );
        if (message) {
          if (message.photo) {
            if(!this.room || typeof this.room === 'string') return
            //this.sendPhotoMessage(this.room, message.photo, message.value);
          }
          if(!this.room || typeof this.room === 'number') return
          //this.sendMessage(this.room, message.userId, message.value);
        }
      };
    }

    console.log('websocket state', self.ws.readyState);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    self.ws.onclose = (e: any) => {
      console.log('Socket is closed. Reconnection will be attempted in 1 second.', e.reason);
      self.ws = null;
      if (!this.closing) {
        setTimeout(() => {
          self.connect(talkingId, roomToken, self);
        }, 1000);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    self.ws.onerror = (err: any) => {
      console.error('Socket encountered error: ', err, 'Closing socket');
      self.ws = null;
      setTimeout(() => {
        self.connect(talkingId, roomToken, self);
      }, 1000);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    self.ws.onmessage = (event: any) => {
      self.message$.next(event);
    };
  }

  close(room: string) {
    this.closing = true;
    // this.subscription.unsubscribe();
    if(!this.ws) return
    this.ws.send(
      JSON.stringify({
        action: 'leaveroom',
        serviceId: 69,
        room,
      })
    );
    this.ws.close();
    this.ws = null;
  }

  sendMessage(room: string, id: number, msg: string) {
    if (this.ws && this.ws.readyState === 1) {
      this.ws.send(
        JSON.stringify({
          action: 'sendmessage',
          serviceId: 69,
          method: 'POST',
          route: '/message',
          room,
          headers: {
            Accept: 'application/x.nda.v4+json',
            Authorization: `Bearer ${this.authService.get('token')}`,
            'Content-Type': 'application/json',
          },
          postdatas: {
            related_user_id: id,
            content: msg,
          },
        })
      );
    } else {
      console.log('Socket is closed. Reconnect will be attempted in 1 second.');
      // setTimeout(() => this.connect(), 1000);
    }
  }

  sendPhotoMessage(id: number, b64: string, msg: string) {
    if(!this.ws) return
    
    this.ws.send(
      JSON.stringify({
        action: 'sendmessage',
        serviceId: 69,
        method: 'POST',
        route: '/photo',
        room: id,
        headers: {
          Accept: 'application/x.nda.v4+json',
          Authorization: `Bearer ${this.authService.get('token')}`,
          'Content-Type': 'application/json',
        },
        postdatas: {
          photo_b64: b64,
          type: 'message',
          talking_id: id,
          content: msg,
        },
      })
    );
  }

  get message() {
    return this.message$.asObservable();
  }

  set message(value) {
    this.message$.next(value);
  }

  send(msg: string) {
    this.ws$.next(msg);
  }
}
