import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { Router, RouterModule, Params } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';

import { AppService } from '../../app.service';
import { AuthService } from '../../auth.service';

import { HeaderComponent } from '../../header/header.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { FooterComponent } from '../../footer/footer.component';

import { User } from '../../shared/interfaces/user';
import { loginOrEmailOrPhoneValidator } from '../../shared/validators/loginOrEmailOrPhoneValidator';

import { ClickAnywhereDirective } from '../../click-anywhere.directive';

@Component({
  selector: 'app-auth-login',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RouterModule, HeaderComponent, CommonModule, ErrorDialogComponent, LoaderComponent, ClickAnywhereDirective, FooterComponent],
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent implements OnInit, OnDestroy {
  @ViewChild('errorMessageDialog') errorMessageDialog!: ElementRef

  authFormGroup: FormGroup
  remember = false
  wrongCredential = false
  error = false
  errorMessage: string | null = null
  errorTitle: string | null = null
  showPassword = false
  openDialog = false
  loading = false
  serviceName = environment.serviceName
  queryParams: Params | null | undefined = null
  inputs: { login: boolean | null, password: boolean | null } = { login: null, password: null }
  loaderSubscription!: Subscription
  formStatus = 'INVALID'

  //key = CryptoJS.enc.Hex.parse('0123456789abcdef0123456789abcdef');
  //iv = CryptoJS.enc.Hex.parse('abcdef9876543210abcdef9876543210');

  constructor(
    private authService: AuthService,
    private router: Router,
    private appService: AppService,
  ) {
    //this.inputs.email = AppInitService.config.site.signInput === 'EMAIL';
    //this.inputs.msisdn = AppInitService.config.site.signInput === 'MSISDN';
    //this.inputs.password = AppInitService.config.site.hasPassword;

    // A SUPPRIMER : pour les tests
    this.inputs.login = true
    this.inputs.password = true
    // A SUPPRIMER : pour les tests

    this.authFormGroup = new FormGroup({
      loginCtrl: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25), loginOrEmailOrPhoneValidator()]),
      passwordCtrl: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(25)]),
    });

    this.appService.setPage('login');
  }

  ngOnInit() {
    console.log('ngoninit')
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.queryParams = params
    // });

    if(this.authService.logged) this.router.navigate(['/home']) // à remettre quand login réparé

    this.authFormGroup.statusChanges.subscribe(res => {
      this.formStatus = res
    })

    // à enlever quand login réparé
    // const user: User = {
    //   id: 0,
    //   firstname: 'Sam',
    //   birthdate: '05-07-1982',
    //   photo: 'assets/rabbit.jpg',
    //   sex: 'man',
    //   attraction: 'woman',
    //   city: {
    //     id: 0, 
    //     name: 'Aix-en-Provence'
    //   },
    //   country: 'France',
    // }

    // this.authService.user.next(user)

    // console.log(user)

    // this.authService.logged$.next(true)

    // this.router.navigate(['/home'])
    // à enlever quand login réparé
  }

  getClickNature(clickNature: string){
    if(clickNature === 'anywhere' && this.openDialog === true){
      this.openDialog = false
    }
  }

  onSubmit(): void {
    console.log('onsubmit')
    const data = this.authFormGroup.value

    this.appService.setLoader(true)

    this.authService.logged$.next(true)
   
    this.router.navigate(['/home'], { queryParams: this.queryParams })

    // à remettre quand login réparé
    this.authService
      .authLogin(data.loginCtrl.trim().toLowerCase(), data.passwordCtrl)
      .subscribe({
        next: ((res: User | null) => {
          this.authService.user.next(res)
          this.authService.logged$.next(true)

          if(!JSON.parse(this.authService.get('user')).main_photo){
            this.router.navigate(['/upload-photo'], { queryParams: this.queryParams })
          } else {
            this.router.navigate(['/home'], { queryParams: this.queryParams })
          }

          this.appService.setLoader(false)
        }), 
        error: (err => {
          if(err.error.message){
            this.errorTitle = "Login details are incorrect"
            this.errorMessage = "Please check and re-enter your data."
            this.openDialog = true
          }

          this.appService.setLoader(false)
        })
      })
    // à remettre quand login réparé
  }

  showHidePassword() {
    this.showPassword = !this.showPassword
  }

  closeDialog(){
    this.openDialog = false
  }
  
  hidingKeyboard() {}
  
  setFocusInput() {}
  
  setBlurInput() {}

  ngOnDestroy(){
    if(this.loaderSubscription) this.loaderSubscription.unsubscribe()
  }
}
