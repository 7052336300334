import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule  } from '@angular/forms';
import { ActivatedRoute, Params, Router, RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { BehaviorSubject, Observable, of, Subject, Subscription, takeUntil } from 'rxjs';

import { ChatService } from '../chat.service';
import { AuthService } from '../../auth.service';
import { AppService } from '../../app.service';
import { UserTinyService } from '../user-tiny/user-tiny.service';
import { ProfileService } from '../../profile/profile.service';
import { WebsocketService } from '../websocket.service';
import { HomeService } from '../../home/home.service';

import { UserTinyComponent } from '../user-tiny/user-tiny.component';

import { Message } from '../../shared/interfaces/message';
import { User } from '../../shared/interfaces/user';
import { UserTiny } from '../../shared/interfaces/user-tiny';
import { ErrorReturn } from '../../shared/interfaces/errorReturn';

@Component({
  selector: 'app-chat-conversation',
  standalone: true,
  imports: [UserTinyComponent, RouterModule, DatePipe, CommonModule, ReactiveFormsModule ],
  templateUrl: './chat-conversation.component.html',
  styleUrl: './chat-conversation.component.scss',
})
export class ChatConversationComponent implements OnInit, OnDestroy {
  @HostBinding('class.fullscreen') openConservation: boolean = false;
  @Input() userDatas!: UserTiny | undefined
  @Input() talkingId!: number | null | undefined;
  @ViewChild('messagesHolder') messagesHolder!: ElementRef;
  @ViewChild('textHolder') textHolder!: ElementRef;
  @ViewChild('textareaHolder') textareaHolder!: ElementRef;
  @ViewChild('photoFileInput') photoFileInput!: ElementRef;

  messageForm!: UntypedFormGroup;

  messages = new BehaviorSubject<Message[]>([])
  isFocused = false;
  loaded = false;
  loadingConversation = true;
  user: User | null = null;
  //userDatas!: UserTiny;
  isNew = false;

  sendingMessage = false;

  silentTalking = false;
  silentTalkingReceived = false;

  roomToken: string | null = null;

  tinyLoader = false;
  loadingMore = false;
  noMoreToLoad = false;
  lastMessageId: number | null = null;

  messagePhoto: string | null = null;
  hasMessageSet = false;
  messageSubscription!: Subscription;

  notSubscribe = false;
  subscribeParams: Params | null = null;

  textareaHeight = 48;

  currentUrl = window.location.href;
  //LP = environment.LP;
  relatedUser!: User | null
  desktopDevice = false

  private readonly destroyed = new Subject<void>()

  constructor(
    private chatService: ChatService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private appService: AppService,
    private websocket: WebsocketService,
    // private angulartics2: Angulartics2,
    private userTinyService: UserTinyService,
    private profileService: ProfileService,
    private homeService: HomeService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((param) => {
      const id: string = param['id']

      if (id) {
        if (id.indexOf('new') > -1) {
          //cas d'une nouvelle conversation
          this.isNew = true
          //this.setNewConversation(parseInt(id.split('-')[1]))
          this.talkingId = 0
          this.chatService.showConversation$.next(0)

          this.authService.getProfile(parseInt(id.split('-')[1])).pipe(
            takeUntil(this.destroyed)
          ).subscribe(res => {
            this.userDatas = this.setUserDatas(res)
            this.relatedUser = res
            if(res.talking_id) this.talkingId = res.talking_id
          })
        } else {
          // cas d'une conversation déjà existante
          if (this.talkingId) {
            this.loadingConversation = true
            this.messages.next([])
            this.lastMessageId = null
          }

          this.chatService.relatedUser.pipe(
            takeUntil(this.destroyed)
          ).subscribe(res => {
            if(!res) return
            this.authService.getProfile(res.user.id).subscribe(res => this.userDatas = this.setUserDatas(res))
          })

          this.talkingId = Number(id)
          this.chatService.showConversation = this.talkingId
          this.getAllMessages(this.talkingId, this.lastMessageId)
        }
      }

      this.scrollToBottom(0)

      this.messageForm = this.formBuilder.group({
        text: [null],
        photo: [null],
      })

      this.activatedRoute.queryParams.subscribe((params) => {
        if (params['success'] === '1' || params['success'] === 1) {
          this.subscribeParams = params

          if (params['alreadysub'] && (params['alreadysub'] === 1 || params['alreadysub'] === '1')) {
            const err: ErrorReturn = {
              code: 500,
              error_code: 404,
              level: 'already subscribe',
              message: '',
            }

            console.log(err)

            //this.appService.setPopin(err);
            this.notSubscribe = true
          } else {
            this.chatService.setSubscribe(params['subscription_id']).pipe(
              takeUntil(this.destroyed)
            ).subscribe((res) => {console.log(res)})
          }
        } else if (params['success'] === '0' || params['success'] === 0) {
          const err: ErrorReturn = {
            code: 500,
            error_code: 404,
            level: 'subscribe notfound',
            message: '',
          }

          console.log(err)
          //this.appService.setPopin(err);
        }
      });
    });
  }
  
  setNewConversation(id: number): Observable<number | null | undefined> {
    console.log('setNewConversation')
    this.chatService.showConversation = Number(id) // id de conversation

    this.authService.getProfile(Number(id)).pipe(
      takeUntil(this.destroyed)
    ).subscribe((res) => {
      this.user = res
      this.userDatas = this.setUserDatas(this.user)

      if (res.relationship === 'notfriend' || res.relationship === 'waiting') {
        this.silentTalking = true
        this.isNew = true
        this.messages.next([])
        this.loadingConversation = false
      } else {
        if (!res.talking_id) {
          this.chatService.createConversation(id).pipe(
            takeUntil(this.destroyed)
          ).subscribe((talkId) => {
            this.talkingId = talkId
            this.getAllMessages(talkId, null)
          });
        } else {
          this.talkingId = res.talking_id
          this.getAllMessages(this.talkingId, null)

          setTimeout(() => console.log('set new conversation', this.messages), 2048)
        }
      }
    });

    return of(this.talkingId)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scrollToBottom(toScroll: any) {
    if (this.messagesHolder && this.messagesHolder.nativeElement.scrollHeight !== toScroll) {
      this.messagesHolder.nativeElement.scrollTop =
        this.messagesHolder.nativeElement.scrollHeight - toScroll;
    } else {
      setTimeout(() => {
        this.scrollToBottom(toScroll);
      }, 512);
    }
  }

  getAllMessages(talkId: number, lastMessageId: number | null, loadingmore: boolean = false) {
    console.log('getAllMessages')
    const toScroll = this.messagesHolder ? this.messagesHolder.nativeElement.scrollHeight : 0

    if (this.messagesHolder && this.messagesHolder.nativeElement.scrollTop < 10) {
      this.messagesHolder.nativeElement.scrollTop = 40
    }

    this.chatService.getMessages(talkId, lastMessageId).pipe(
      takeUntil(this.destroyed)
    ).subscribe((res) => {
      this.loaded = true

      if (res.messages.length === 0 && this.messages.value.length === 0) {
        this.isNew = true
      } else {
        this.isNew = false
        let tArray!: Message[]
        if(this.messages.value){
          tArray = res.messages.concat(this.messages.value)
        } else {
          tArray = res.messages
        }
        
        this.messages.next(tArray)
        this.lastMessageId = this.messages.value[0].id

        this.messages.value.map(
          (m: Message) => {
            m.text = decodeURIComponent(m.text)
            m.today = this.checkIfToday(m.created_at)
            m.yesterday = this.checkIfYesterday(m.created_at)
            if (m.photos) {
              m.photos.error = false
              m.photos.loaded = false
            }
          }
        )
      }

      this.loadingConversation = false

      if (!loadingmore) {
        this.user = res.user
        let relatedUser!: User
        
        this.authService.getProfile(res.talking.related_user.id).pipe(
          takeUntil(this.destroyed)
        ).subscribe(res => {
          relatedUser = res
          this.userDatas = this.setUserDatas(relatedUser)
        })
        
        // this.userDatas = this.setUserDatas(this.user)
        this.roomToken = res.room
        this.websocketConnection(talkId, toScroll)
        this.silentTalkingReceived = res.silent && res.messages[0] && res.messages[0].user_id === this.user.id
      } else {
        this.tinyLoader = false
        this.noMoreToLoad = res.messages.length < 10
        this.loadingMore = false
      }
    })
  }

  websocketConnection(
    talkingId: number,
    toScroll: number,
    msg?: { userId: number; value: string; photo: string }
  ) {
    if(!this.roomToken) return

    this.websocket.connect(Number(talkingId), this.roomToken, null, msg)
    console.log('websocket connect')

    this.websocket.message.pipe(
      takeUntil(this.destroyed)
    ).subscribe((m) => {
      const message = JSON.parse(m.data)
      const timestamp = message.timestamp * 1000

      if (message.code !== 500) {
        this.messages?.value?.push({
          created_at: timestamp,
          id: message.message.id,
          photos: message.photo,
          text: decodeURIComponent(message.message.text),
          user_id: message.user_id,
          today: this.checkIfToday(timestamp),
          yesterday: this.checkIfYesterday(timestamp),
        })

        if (this.messagePhoto) {
          // this.angulartics2.eventTrack.next({
          //   action: 'clic_send_picture',
          //   properties: {
          //     category: 'cat_conversation',
          //   },
          // });
        }
        
        if (message.message.text !== '') {
          // this.angulartics2.eventTrack.next({
          //   action: 'clic_send_message',
          //   properties: {
          //     category: 'cat_conversation',
          //   },
          // });
        }
      } else {
        if (message.error_code === 32) {
          this.nonSubscriptionPopup()
        }
      }

      if (this.sendingMessage) {
        this.sendingMessage = false
        this.textHolder.nativeElement.innerHTML = ''
        this.removeMessagePhoto()
        this.isNew = false

        this.messageForm.patchValue({
          text: null,
        });

        this.textHolder.nativeElement.innerHTML = ''
        this.textareaHeight = this.textHolder.nativeElement.offsetHeight
        this.textareaHolder.nativeElement.focus()
      }

      this.checkIfMessage()

      setTimeout(() => {
        this.scrollToBottom(toScroll)
      }, 512)
    });
  }

  nonSubscriptionPopup() {
    this.notSubscribe = true

    if (this.messagePhoto) {
      // this.angulartics2.eventTrack.next({
      //   action: 'clic_send_picture_nonabo',
      //   properties: {
      //     category: 'cat_conversation',
      //   },
      // });
    } else {
      // this.angulartics2.eventTrack.next({
      //   action: 'clic_send_message_nonabo',
      //   properties: {
      //     category: 'cat_conversation',
      //   },
      // });
    }
  }

  checkIfToday(time: number) {
    const today = new Date().toDateString();
    const date = new Date(time).toDateString();
    return date === today;
  }

  checkIfYesterday(time: number) {
    const today = new Date();
    const date = new Date(time).toDateString();
    const yesterday = new Date(today.setDate(today.getDate() - 1)).toDateString();
    return date === yesterday;
  }

  setUserDatas(user: User): UserTiny {
    return {
      user: {
        firstname: user.firstname,
        type: 'friend',
        id: user.id,
        gendre: user.sex,
        photo: user.main_photo ? user.main_photo.url : null,
      },
      message: null,
      photos: false,
      time: null,
      /* actions: {
        block: true,
        reportUser: true,
        remove: true,
      }, */
      talking: null,
    };
  }

  focused(value: boolean) {
    this.isFocused = value;
  }

  // imageLoaded(el) {
  //   el.loaded = true;
  // }

  // imageError(el) {
  //   el.error = true;
  // }

  typing(e: Event) {
    console.log(e)
    this.checkIfMessage();
    this.textHolder.nativeElement.innerHTML = this.messageForm.value.text;
    //this.textareaHeight = this.textHolder.nativeElement.offsetHeight;
  }

  checkIfMessage() {
    this.hasMessageSet =
      this.messagePhoto || (this.messageForm.value.text && this.messageForm.value.text !== '');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onPhotoInputChange($event: any) {
    const reader = new FileReader();
    const [file] = $event.target.files;
    reader.readAsDataURL(file);
    reader.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      const imageResult = reader.result;
      const img = new Image();
      img.src = imageResult as string;
      img.onload = () => {
        let width = 0;
        let height = 0;
        const landscape = img.width > img.height;
        if (landscape) {
          width = Math.min(500, img.width);
          height = (width / img.width) * img.height;
        } else {
          height = Math.min(500, img.height);
          width = (height / img.height) * img.width;
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        this.messagePhoto = canvas.toDataURL('image/jpeg', 0.5);
        this.hasMessageSet = true;
      };
    };
  }

  sendMessageCheck() {
    const message = this.messageForm.value.text ? encodeURIComponent(this.messageForm.value.text) : ''
    this.sendingMessage = true

    if (this.silentTalking) {
      // this.profilService
      //   .askForSilentTalking(this.userDatas?.user.id)
      //   .pipe(
      //     catchError((err) => {
      //       if (err.error.error_code === 32) {
      //         this.nonSubscriptionPopup();
      //       }
      //       return of(null);
      //     })
      //   )
      //   .subscribe((res) => {
      //     console.log(res);
      //     this.roomToken = res.response ? res.response.data.room_talking : res.data.room_talking;
      //     this.talkingId = res.response ? res.response.data.talking_id : res.data.talking_id;
      //     this.silentTalking = false;
      //     // this.websocketConnection(this.talkingId, 0, {
      //     //   userId: Number(this.userDatas.user.id),
      //     //   value: message,
      //     //   photo: this.messagePhoto,
      //     // });
      //   });
    } else if (this.silentTalkingReceived) {
      if(!this.talkingId) return
      this.chatService.changeSilentTalking(this.talkingId, '0').pipe(
        takeUntil(this.destroyed)
      ).subscribe(() => {
        this.silentTalkingReceived = false;
        this.sendMessage(message);
      });
    } else {
      if(!this.talkingId || this.talkingId === 0) {
        if(!this.relatedUser) return
        this.setNewConversation(this.relatedUser.id).pipe(
          takeUntil(this.destroyed)
        ).subscribe(talkId => {
          this.talkingId = talkId
          this.sendMessage(message);
        })
      }

      this.sendMessage(message);
    }
  }

  onSubmit(){
    this.sendMessageCheck()
  }

  sendMessage(message: string) {
    console.log('sendMessage: ', message)
    
    if (this.messagePhoto) {
      //this.websocket.sendPhotoMessage(this.talkingId, this.messagePhoto, message);
    } else {
      // this.chatService.setMessage(this.relatedUser.id, message).pipe(
      //   switchMap((res) => {
      //     console.log(res)
      //     if(!this.talkingId) return
      //     this.websocket.sendMessage(this.talkingId.toString(), this.relatedUser.id, message)
      //     return res
      //   })
      // ).pipe(map(res => return res)).subscribe({
      //   next: (res) => console.log(res),
      //   error: err => console.error(err.message),
      //   complete: () => console.log("service call 1 used to create service call 2, which is complete")
      // })

      if(!this.userDatas?.user.id) return
      this.chatService.setMessage(this.userDatas.user.id, message).pipe(
        takeUntil(this.destroyed)
      ).subscribe(() => {
        if(!this.talkingId || !this.userDatas) return
        this.websocket.sendMessage(this.talkingId.toString(), this.userDatas.user.id, message)
      })
    }
  }

  removeMessagePhoto() {
    this.messagePhoto = null;
    this.photoFileInput.nativeElement.value = null;
    this.checkIfMessage();
  }

  onTalkScroll($event: Event) {
    const container = $event.target as HTMLElement;
    const bottom = container.scrollTop < 30;

    if (bottom && !this.loadingMore && !this.noMoreToLoad) {
      this.tinyLoader = true;
      this.loadingMore = true;

      if(!this.talkingId || !this.lastMessageId) return

      this.getAllMessages(this.talkingId, this.lastMessageId, true);
    }
  }

  redirect() {
    // this.angulartics2.eventTrack.next({
    //   action: 'clic_activer_abonnement',
    //   properties: {
    //     category: 'cat_conversation',
    //   },
    // });
    // window.open(this.LP + this.currentUrl, '_self');
  }

  acceptSilentTalking() {
    if(!this.talkingId) return

    this.chatService.changeSilentTalking(this.talkingId, '0').pipe(
      takeUntil(this.destroyed)
    ).subscribe((res) => {
      console.log(res)
      this.silentTalkingReceived = false;

      if(!this.userDatas?.user.id || !this.talkingId) return

      this.userTinyService.throwSuccess({
        value: null,
        type: 'accept',
        user: this.userDatas?.user.id,
        talking: this.talkingId,
        feed: null,
      });
    });
  }

  deniedSilentTalking() {
    if(!this.userDatas?.user.id || !this.talkingId) return

    this.userTinyService.throwSuccess({
      value: null,
      type: 'delete',
      user: this.userDatas.user.id,
      talking: this.talkingId,
      feed: null,
    });
    // this.appService.setLoader({
    //   timer: false,
    //   display: true,
    //   value: 'talking',
    //   type: 'delete',
    // });
    this.chatService.changeSilentTalking(this.talkingId, '0').pipe(
      takeUntil(this.destroyed)
    ).subscribe(() => {
      if(!this.talkingId) return

      this.chatService.deleteTalking(this.talkingId).pipe(
        takeUntil(this.destroyed)
      ).subscribe(() => {
        if(!this.user) return
        this.homeService.deleteRelationship(this.user.id).pipe(
          takeUntil(this.destroyed)
        ).subscribe(() => {
          // this.appService.setLoader({
          //   timer: false,
          //   display: false,
          //   value: null,
          //   type: null,
          // });
          this.router.navigate(['/service/chat']);
        });
      });
    });
  }

  ngOnDestroy(){
    this.chatService.showConversation = null;
    this.destroyed.next();
    this.destroyed.complete();    
  }
}
