<!-- <app-loader-inside
  *ngIf="loadingConversation; else conversation"
  [icon]="'message'"
  [value]="'conversation'"
></app-loader-inside> -->

@if(talkingId){
  <div class="dialog-user">
    <div class="dialog-user-left">
      <app-user-tiny
        [datas]="userDatas"
        [direction]="'vertical'"
        [feed]="false"
        [relatedUser]="relatedUser"
      ></app-user-tiny><!-- [link]="'/service/profil/' + userDatas?.user?.id" -->
    </div>

    @if(silentTalkingReceived){
      <div
        class="button-show-profile"
      ><!-- [routerLink]="'/service/profil/' + userDatas?.user?.id" -->
        voir le profil
      </div>
    }
  </div>

  <div class="dialog-text"><!--  [class.subscribe]="notSubscribe" -->
    @if(!isNew){
      <div
        class="messages"
        #messagesHolder
        (scroll)="onTalkScroll($event)"
      >
        <!-- <div class="tiny-loader" [class.show]="tinyLoader">
          <div class="tiny-loader-bar"></div>
          <div class="tiny-loader-text">Loading</div>
        </div> -->

        @if(messages.value.length > 0){
          @for(message of messagesFromSubscription; track message){
            <div
              class="message"
              [class.you]="user?.id !== message.user_id"
            >
              @if(user?.id === message.user_id){
                <div
                  class="dialog-user-photo"
                  [routerLink]="['/service/profil/', user?.id]"
                >
                  @if(user?.main_photo){
                    @if(!user?.loaded && !user?.error){
                      <div class="loading">404</div>
                    }
  
                    @if(user?.error){
                      <div class="error">404</div>
                    }
                    
                    <img
                      [src]="user?.main_photo?.url"
                      [class.ready]="user?.loaded && !user?.error"
                      alt=""
                    />
                    <!-- (load)="imageLoaded(user)"
                      (error)="imageError(user)" -->
                  } @else {
                    <div class="nophoto">
                      no photo icon
                     <!-- <mat-icon [svgIcon]="user.sex === 'man' ? 'man' : 'woman'"></mat-icon>  -->
                    </div>
                  }
                </div>
              }
              
              <div class="message-text">
                <span>
                  @if(message.today){
                    Today
                  }
                  
                  @if(message.yesterday){
                    Yesterday
                  }
  
                  @if(!message.yesterday && !message.today){
                    {{ message.created_at | date: 'd MMM yyy' }}
                  }
                </span>
  
                @if(message.photos){
                  <img
                    [src]="message.photos.url"
                    [class.withText]="message.text"
                    alt=""
                  /><!-- (load)="imageLoaded(message.photos)"
                  (error)="imageError(message.photos)"  -->
                }
  
                @if(message.text){
                  <p [class.withPhoto]="message.photos">{{ message.text }}</p>
                }
              </div>
              <ng-template #picture> </ng-template>
            </div>
          }
        }
          
      </div>
    } @else {
      <div class="messages empty">
        <h2>Start a new conversation</h2>
        <span class="sayhello">Say hello to</span>
        <span class="helloname">{{ user?.firstname }}</span>
        down arrow icon
        <!-- <mat-icon svgIcon="downArrow"></mat-icon> -->
      </div>
    }
  </div>

  <div class="dialog-actions" [class.subscribe]="notSubscribe">
    @if(silentTalkingReceived){
      <div class="silent-talking-actions">
        <span>Si vous répondez, vous serez ami sur woozgo et peut être pourrez vous coucher ce soir</span>
        <div class="silent-talking-actions-holder">
          <button class="ok" (click)="acceptSilentTalking()">
            ok icon
            <!-- <mat-icon svgIcon="ok"></mat-icon> -->
            <span class="label">Accepter</span>
          </button>
          <button (click)="deniedSilentTalking()">
            denied icon
            <!-- <mat-icon svgIcon="denied"></mat-icon> -->
            <span class="label">Refuser</span>
          </button>
        </div>
      </div>
    }
    
    @if(messagePhoto){
      <div class="dialog-actions-photo-holder">
        <div class="dialog-actions-photo-img">
          <button type="button" class="dialog-actions-photo-remove" (click)="removeMessagePhoto()">
            close icon
            <!-- <mat-icon svgIcon="close"></mat-icon> -->
          </button>
          <img [src]="messagePhoto" alt=""/>
        </div>
      </div>
    }
    
    <div class="dialog-actions-actions">
      @if(!notSubscribe){
        <form
          [formGroup]="messageForm"
          (ngSubmit)="(sendingMessage)"
        >
          <input
            #photoFileInput
            type="file"
            formControlName="photo"
            accept="image/*"
            (change)="onPhotoInputChange($event)"
          />
          
          <button type="button" class="add-photo-btn" (click)="photoFileInput.click()">
            <img src="assets/icons/icon_gallery.svg" alt="" class="gallery-icon">
          </button>

          <div class="input-holder">
            <div class="input-faketext" #textHolder [class.hide]="sendingMessage"></div>
            <textarea
              class="input-text"
              #textareaHolder
              placeholder="Message"
              [class.hide]="sendingMessage"
              (keyup)="typing($event)"
              formControlName="text"
              [ngStyle]="{ height: textareaHeight + 'px' }"
              (focus)="this.isNew = false"
              spellcheck="false"
            ></textarea>
            <span class="sending" [class.hide]="!sendingMessage">Sending Message</span>
          </div>

          <button
            type="submit"
            [disabled]="sendingMessage || !hasMessageSet"
            (click)="sendMessageCheck()"
            class="send-btn"
          >
            <img src="assets/icons/icon_send.svg" alt="" class="send-icon">
          </button>
        </form>
      } @else {
        <div class="subscribing">
          <div class="subscribing-icon">
            warning icon
            <!-- <mat-icon svgIcon="warning"></mat-icon> -->
          </div>
          <h2>You should be premium guest to send messages</h2>
          <div class="subscribing-explaination">
            Follow the link below to become a premium guest. Premium guest can send unlimited
            message for only 24.99 euros per day, it's just a wonderful gift for you to find true
            love with a social network
          </div>
          <button class="subscribing-action" (click)="redirect()">
            Become a premium guest
          </button>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="conversations-current">
    <div class="icon-container">
        <img src="../../assets/icons/icon_chat_with.svg" alt="">
    </div>
    <h3>My conversations</h3>
  </div>
}