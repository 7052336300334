<h3>Are you sure you wish to flag this story?</h3>

<p class="description">This story will be monitored by our moderation teams.</p>

<div class="actions">
  <button class="text-btn" (click)="dialogRef.close()">Cancel</button>
  <button class="submit" (click)="dialogRef.close(true)">
    <img src="assets/icons/icon_flag.svg" alt="" class="flag-icon">
    Flag
  </button>  
</div>
