import { Component, ViewEncapsulation, OnInit, ViewChild, ElementRef, AfterViewChecked, OnDestroy, Output, EventEmitter, inject } from '@angular/core';
import { CarouselModule, CarouselComponent } from 'ngx-carousel-ease';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { filter, Subscription, switchMap } from 'rxjs';
import dayjs from 'dayjs';
import { Dialog } from '@angular/cdk/dialog';

import { AppService } from '../../app.service';
import { ProfileService } from '../profile.service';
import { HomeService } from '../../home/home.service';
import { EncountersService } from '../../new-encounters/encounters.service';

import { InfoDialogComponent } from '../../dialog/info-dialog/info-dialog.component';
import { ProfileDialogComponent } from '../../dialog/profile-dialog/profile-dialog.component';
import { BlockUserDialogComponent } from '../../dialog/block-user-dialog/block-user-dialog.component';
import { ReportUserDialogComponent } from '../../dialog/report-user-dialog/report-user-dialog.component';
import { UnmatchDialogComponent } from '../../dialog/unmatch-dialog/unmatch-dialog.component';
import { MatchDialogComponent } from '../../dialog/match-dialog/match-dialog.component';

import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-profile-space',
  standalone: true,
  imports: [CarouselModule, CarouselComponent, RouterModule, CommonModule, InfoDialogComponent, MatchDialogComponent],
  templateUrl: './profile-space.component.html',
  styleUrl: './profile-space.component.scss',
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    'class': 'space'  // <-- It should be unique for each component you have encapsulation off
  }
})
export class ProfileSpaceComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('hobbiesContainer') hobbiesContainer!: ElementRef
  @Output() emitInfoDialogType = new EventEmitter<string>()

  profile!: User | null
  loading = false
  desktopDevice = false
  userAge!: number
  hobbyWidth!: number
  loaderSubscription!: Subscription
  routeDataSubscription!: Subscription
  profileSubscription!: Subscription
  infoDialogType = ''
  relationshipStatus = ''
  dialog = inject(Dialog)
  match = false
  showWaitingStatus = false
  futureRelationshipStatus = ''

  constructor(
    private appService: AppService,
    private profileService: ProfileService,
    private homeService: HomeService,
    private encountersService: EncountersService
  ) { }

  ngOnInit(): void {
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    this.profileSubscription = this.profileService.profile.subscribe(res => {
      this.profile = res

      if(!this.profile) return

      if(this.profile && this.profile.birthdate){
        const [y,m,d] = this.profile.birthdate.split('-')
        const date = `${y}-${m}-${d}`
        const birthDate = dayjs(date)
  
        this.userAge = dayjs().diff(birthDate, 'year')
      }

      this.encountersService.getRelationshipStatus(this.profile.id).subscribe(res => {
        this.relationshipStatus = res

        if(this.relationshipStatus === 'waiting') this.showWaitingStatus = true
      })
    })

    // gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }

    this.desktopDevice = this.appService.desktopDevice
  }

  ngAfterViewChecked(){
    if(this.desktopDevice && this.hobbiesContainer){
      const bounds = this.hobbiesContainer.nativeElement.getBoundingClientRect()
      const hobbiesContainerWidth = bounds.right - bounds.left
      this.hobbyWidth = (hobbiesContainerWidth) / 4 - 8
    } else {
      const screenWidth = window.screen.width
      this.hobbyWidth = (screenWidth - 48) / 3 - 8
    }
  }

  getStyles() {
    return {
      'width': this.hobbyWidth + 'px',
      'height': this.hobbyWidth + 'px',
    }
  }

  closeDialog(event: boolean){
    this.homeService.dialogDisplayed.next(!event)
    this.infoDialogType = ''
  }

  openProfileDialog(e: Event) {
    e.stopPropagation()
    this.dialog.open(ProfileDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe((res) => {
      console.log(res)
      if(res === 'unmatch'){
        this.unmatchDialog()
      } else if(res === 'block'){
        this.blockUserDialog()
      } else {
        this.blockAndReportUserDialog()
      }
    })
  }

  unmatchDialog() {
    this.dialog.open(UnmatchDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.profile) return

      this.homeService.deleteRelationship(this.profile.id).subscribe(res => {
        console.log(res)
      })
    })
  }

  matchDialog() {
    this.dialog.open(MatchDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
      data: this.profile
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.profile) return

      // TODO : envoyer un message
      console.log('send message')
    })
  }

  blockUserDialog() {
    this.dialog.open(BlockUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.profile) return
      this.homeService.updateRelationship(this.profile.id, 'blocked').subscribe(res => console.log(res))
    })
  }

  blockAndReportUserDialog() {
    this.dialog.open(ReportUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.profile) return

      this.homeService.createReportBetweenUsers(this.profile.id, 'user').subscribe(() => {
        if(!this.profile) return
        this.homeService.updateRelationship(this.profile.id, 'blocked_with_report').subscribe(res => console.log(res))
      })
    })
  }

  rejectProfile(userId: number){
    console.log('rejectProfile')
    let state = 'refused'

    // refuse si relation est waiting. Si déjà refused, passe à rejected
    this.encountersService.getRelationshipStatus(userId).subscribe(res => {
      console.log(res)
      if(res === "rejected") state = 'rejected'
    })

    this.homeService.updateRelationship(userId, state).subscribe(res => {
      console.log(res)
      this.encountersService.markProfileAsSeen(userId).subscribe(res => console.log(res.message))
    })
  }

  likeProfile(userId: number){
    console.log('likeProfile ', userId)

    // le user peut liker jusqu'à 5 fois sans être abonné. Si le user n'est pas abonné et qu'il a déjà liké 5 fois, on affiche la popup. En cliquant sur le bouton, le user sera redirigé vers la landing page.
    // TODO : en attente de dev back pour gérer le nombre de likes
    // this.infoDialogType = 'likeProfile'
    // this.homeService.dialogDisplayed.next(true)

    // si le user est déjà abonné, on met à jour la relation
    let state = ''
    if(this.relationshipStatus === 'notfriend'){// la relation n'existe pas
      state = 'accepted'
      this.futureRelationshipStatus = 'waiting'// le user connecté like un autre profil
    } else if (this.relationshipStatus === 'pending') {// le profil du user connecté a été liké
      state = 'validated'
      this.futureRelationshipStatus = 'friend'
    }
    
    this.homeService.updateRelationship(userId, state).pipe(
      switchMap(() => {
        console.log('return switch map')
        // si la relation était à waiting, elle passe à accepted/pending. Si elle était à pending, elle passe à validated/friend
        return this.encountersService.getRelationshipStatus(this.profile!.id)})
    ).subscribe({
      next: (res) => {
        this.relationshipStatus = res
        this.profileService.profileLiked.next(true)

        setTimeout(() => {
          this.profileService.profileLiked.next(false)
        }, 3000)
      }, 
      error: err => console.error(err.message),
      complete: () => { 
        if(this.futureRelationshipStatus === 'waiting'){
          this.showWaitingStatus = true
        } else if(this.futureRelationshipStatus === 'friend'){
          this.match = true
          this.matchDialog()
        }
        this.encountersService.markProfileAsSeen(userId).subscribe(res => console.log(res.message))
      }
    })
  }

  contactProfile(userId: number){
    console.log('contactProfile: ', userId)

    // la boîte de dialogue ne sera affichée que si le user n'est pas abonné. En cliquant sur le bouton, le user sera redirigé vers la landing page
    this.infoDialogType = 'contactProfile'
    this.homeService.dialogDisplayed.next(true)

    // si le user est déjà abonné, on ouvre une conversation - TODO
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
    this.profileSubscription.unsubscribe()
  }
}
