import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import dayjs from 'dayjs';

import { ChatService } from '../chat.service';
import { HomeService } from '../../home/home.service';
import { MatchesService } from '../../my-matches/matches.service';

import { ConversationDialogComponent } from '../../dialog/conversation-dialog/conversation-dialog.component';
import { UnmatchDialogComponent } from '../../dialog/unmatch-dialog/unmatch-dialog.component';
import { BlockUserDialogComponent } from '../../dialog/block-user-dialog/block-user-dialog.component';
import { ReportUserDialogComponent } from '../../dialog/report-user-dialog/report-user-dialog.component';
import { DeleteConversationDialogComponent } from '../../dialog/delete-conversation-dialog/delete-conversation-dialog.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { User } from '../../shared/interfaces/user';
import { UserTiny } from '../../shared/interfaces/user-tiny';

@Component({
  selector: 'app-user-tiny',
  standalone: true,
  imports: [RouterModule, DatePipe, GoBackButtonComponent],
  templateUrl: './user-tiny.component.html',
  styleUrl: './user-tiny.component.scss'
})
export class UserTinyComponent implements OnInit, OnDestroy {
  //@Input() relatedUser!: User | null
  @Input() data!: UserTiny | undefined
  @Input() new!: boolean
  @Input() newMessages!: number
  @Input() origin!: string
  @Output() selectedTalkId: EventEmitter<number> = new EventEmitter
  
  user = new BehaviorSubject<User | null>(null)
  dialog = inject(Dialog)
  loggedIn = false
  matchProfile!: User | undefined
  private readonly destroyed = new Subject<void>()
  
  constructor(
    private chatService: ChatService,
    private homeService: HomeService,
    private location: Location,
    private matchesService: MatchesService,
  ){}

  ngOnInit(){
    console.log('ngoninit user tiny')
    this.matchesService.matches.pipe(
          takeUntil(this.destroyed)
        ).subscribe(matches => {
      if(!matches) return

      let matchProfile!: User | undefined

      if(!this.data?.user && !this.chatService.relatedUser.value) return

      if(this.data?.user.id){
        matchProfile = matches.find(m => m.id == this.data?.user.id)
      
        if(!matchProfile) return
        const profileLastConnectionDate = dayjs(matchProfile.connected_at)
        const today = dayjs(new Date())
        const timeDiff = today.diff(profileLastConnectionDate, 'day')

        if(timeDiff <= 1) this.loggedIn = true
      } else {
        const relatedUserId = this.chatService.relatedUser.value?.user.id

        matchProfile = matches.find(m => m.id == relatedUserId)

        this.matchProfile = matchProfile
      
        if(!matchProfile) return
        const profileLastConnectionDate = dayjs(matchProfile.connected_at)
        const today = dayjs(new Date())
        const timeDiff = today.diff(profileLastConnectionDate, 'day')

        if(timeDiff <= 1) this.loggedIn = true
      }
    }) 
  }

  /* viewProfile(relatedUserId: number){
    console.log(relatedUserId)
  } */

  openTalk(data?: UserTiny | null){
    console.log(data)
    if(!data) return
    this.chatService.relatedUser.next(data)
  }

  toggleSidenav(open: boolean, componentRequested: string){
    this.chatService.toggleSidenav.next(open)
    this.chatService.componentDisplayed.next(componentRequested)
  }

  openConversationDialog() {
    this.dialog.open(ConversationDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => res !== ''),
    ).subscribe((res) => {
     switch (res) {
      case 'unmatch':
        this.unmatchDialog()
        break;
      case 'block':
        this.blockUserDialog()
        break;
      case 'report':
        this.blockAndReportUserDialog()
        break;
      default:
        this.deleteConversationDialog()
        break;
     }
    })
  }

  unmatchDialog() {
    this.dialog.open(UnmatchDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.user.value) return

      this.homeService.deleteRelationship(this.user.value.id).pipe(
        takeUntil(this.destroyed)
      ).subscribe(res => {
        console.log(res)
      })
    })
  }

  blockUserDialog() {
    this.dialog.open(BlockUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.user.value) return
      this.homeService.updateRelationship(this.user.value.id, 'blocked').pipe(
        takeUntil(this.destroyed)
      ).subscribe(res => console.log(res))
    })
  }

  blockAndReportUserDialog() {
    this.dialog.open(ReportUserDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.user.value) return

      this.homeService.createReportBetweenUsers(this.user.value.id, 'user').pipe(
        takeUntil(this.destroyed)
      ).subscribe(() => {
        if(!this.user.value) return
        this.homeService.updateRelationship(this.user.value.id, 'blocked_with_report').pipe(
          takeUntil(this.destroyed)
        ).subscribe(res => console.log(res))
      })
    }) 
  }

  deleteConversationDialog() {
    this.dialog.open(DeleteConversationDialogComponent, {
      minWidth: '300px',
      maxWidth: '450px',
      width: '100vw',
    }).closed.pipe(
      filter(res => !!res)
    ).subscribe(() => {
      if(!this.chatService.talkingId.value) return
      this.chatService.deleteTalking(this.chatService.talkingId.value).pipe(
        takeUntil(this.destroyed)
      ).subscribe(res => console.log(res))
    }) 
  }

  goBack(){
    this.location.back()
    this.homeService.pageDisplayed.next('my-matches')
    this.chatService.toggleSidenav.next(false)
  }

  ngOnDestroy(){
    this.destroyed.next();
    this.destroyed.complete();    
  }
}
