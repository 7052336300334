import { Injectable } from '@angular/core';
import { User } from '../shared/interfaces/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profile = new BehaviorSubject<User | null>(null)
  profileLiked = new BehaviorSubject<boolean>(false)

  constructor() { }
}
