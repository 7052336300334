import { Component, ElementRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, Validators, ReactiveFormsModule, FormGroup, FormControl } from '@angular/forms';
import { Subscription, switchMap } from 'rxjs';
import { Location, CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';

import { AppService } from '../../app.service';
import { AuthService } from '../../auth.service';
import { CriteriaService } from '../../criteria.service';
import { HomeService } from '../../home/home.service';

import { LoaderComponent } from '../../shared/loader/loader.component';
import { RangeInputComponent } from '../../shared/range-input/range-input.component';
import { CityInputComponent } from '../../shared/city-input/city-input.component';
import { TopButtonComponent } from '../../shared/top-button/top-button.component';
import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { City } from '../../shared/interfaces/city';
import { User } from '../../shared/interfaces/user';
import { Criteria } from '../../shared/interfaces/criteria';

@Component({
  selector: 'app-my-dating-criteria',
  standalone: true,
  imports: [
    LoaderComponent, 
    FormsModule, 
    ReactiveFormsModule, 
    RangeInputComponent, 
    CityInputComponent,
    TopButtonComponent, 
    GoBackButtonComponent, 
    CommonModule, 
    MatSliderModule
  ],
  templateUrl: './my-dating-criteria.component.html',
  styleUrl: './my-dating-criteria.component.scss'
})
export class MyDatingCriteriaComponent implements OnInit, OnDestroy {
  @ViewChild('top') top!: ElementRef

  loading = false
  datingCriteriaFormGroup!: FormGroup
  error = false

  user!: User | null
  userSex = ''
  userCityId = ''

  distanceValue = 50
  loaderSubscription!: Subscription
  desktopDevice = false

  criteria!: Criteria
  ageRange = {min: 24, max: 40}
  successMessage: string | null = null

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private criteriaService: CriteriaService,
    private location: Location,
    private homeService: HomeService,
  ){}

  ngOnInit(){
    this.desktopDevice = this.appService.desktopDevice
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // this.userSex = this.authService.get('sex')
    // this.userCityId = this.authService.get('city_id')
    this.user = JSON.parse(this.authService.get('user'))

    if(!this.user) {
      this.authService.authMe.subscribe(res => {
        this.user = res
      })
    }

    this.datingCriteriaFormGroup = new FormGroup({
      //attractionCtrl: new FormControl(this.user?.sex === 'man' ? Gender.woman : Gender.man, [Validators.required]),
      minAgeCtrl: new FormControl(this.ageRange.min, [Validators.required]), 
      maxAgeCtrl: new FormControl(this.ageRange.max, [Validators.required]), 
      locationCtrl: new FormControl(this.user?.city?.id || 0, [Validators.required]),
      distanceCtrl: new FormControl(this.distanceValue, [Validators.required]),
    });

    this.criteriaService.getCriterias().subscribe(res => {
      this.criteria = res

      //this.datingCriteriaFormGroup.controls['attractionCtrl'].patchValue(this.criteria.sex)
      this.datingCriteriaFormGroup.controls['minAgeCtrl'].patchValue(this.criteria.age_start)
      this.datingCriteriaFormGroup.controls['maxAgeCtrl'].patchValue(this.criteria.age_end)
      this.datingCriteriaFormGroup.controls['locationCtrl'].patchValue(this.criteria.city.id)
      this.datingCriteriaFormGroup.controls['distanceCtrl'].patchValue(this.criteria.distance)

      this.ageRange = { min: this.criteria.age_start, max: this.criteria.age_end}
      this.distanceValue = this.criteria.distance
    })

    this.successMessage = null
  }

  goBack(){
    if(this.desktopDevice) {
      this.location.back()
    }

    this.homeService.pageDisplayed.next('')
  }

  getScrollRequest(request: string){
    if(request === 'scrollToTop') this.top.nativeElement.scrollIntoView()
  }

  formatLabel(value: number): string {
    return `${value}\xa0km` // espace insécable
  }

  onSubmit(){//data: {attractionCtrl: Gender.man | Gender.woman, minAgeCtrl: number, maxAgeCtrl: number, locationCtrl: number, distanceCtrl: number}
    const data = this.datingCriteriaFormGroup.value

    this.criteriaService.setCriteria(data).pipe(
      switchMap(() => this.criteriaService.getCriterias())
    ).subscribe(() => this.successMessage = 'Your criteria have been updated.')
  }

  getCitySet($event: City | { id: number | null; name: string | null; }){
    this.datingCriteriaFormGroup.controls['locationCtrl'].setValue($event.id)
  }

  getDistanceValue(e: number){
    this.distanceValue = e
  }

  getMinValueSelection($event: number){
    this.datingCriteriaFormGroup.controls['minAgeCtrl'].setValue($event)
  }

  getMaxValueSelection($event: number){
    this.datingCriteriaFormGroup.controls['maxAgeCtrl'].setValue($event)
  }

  // getAttraction($event: Gender.man | Gender.woman){
  //   this.datingCriteriaFormGroup.controls['attractionCtrl'].setValue($event)
  // }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
