<button class="text-btn" (click)="dialogRef.close('delete')">
  <img src="assets/icons/icon_chat_delete.svg" alt="" class="icon delete-icon">
  Delete conversation
</button>
<button class="text-btn" (click)="dialogRef.close('unmatch')">
  <img src="assets/icons/icon_unmatch.svg" alt="" class="icon unmatch-icon">
  Unmatch
</button>
<button class="text-btn" (click)="dialogRef.close('block')">
  <img src="assets/icons/icon_block.svg" alt="" class="icon block-icon">
  Block user
</button>
<button class="text-btn" (click)="dialogRef.close('report')">
  <img src="assets/icons/icon_report_block.svg" alt="" class="icon report-icon">
  Report & Block
</button>