import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';

import { UserTinyService } from './user-tiny.service';
import { HomeService } from '../../home/home.service';
import { ChatService } from '../chat.service';

import { GoBackButtonComponent } from '../../shared/go-back-button/go-back-button.component';

import { UserTiny } from '../../shared/interfaces/user-tiny';
import { User } from '../../shared/interfaces/user';

@Component({
  selector: 'app-user-tiny',
  standalone: true,
  imports: [DatePipe, RouterModule, GoBackButtonComponent],
  templateUrl: './user-tiny.component.html',
  styleUrl: './user-tiny.component.scss'
})
export class UserTinyComponent implements OnInit {
  @Input() datas!: UserTiny |undefined;
  @Input() direction = 'horizontal';
  @Input() link!: string;
  @Input() feed!: boolean;
  @Input() talkIsRead!: boolean;
  @Input() avatar = true;
  @Input() relatedUser!: User
  @Output() redirect = new EventEmitter<boolean>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() confirmOn = new EventEmitter<any>();
  @Output() talkSelected = new EventEmitter<number | null | undefined>()

  updating = false;

  actions = false;
  confirm = null;

  constructor(
    //private serviceService: ServiceService,
    //private feedService: FeedService,
    //private profilService: ProfilService,
    //private angulartics2: Angulartics2,
    private userTinyService: UserTinyService,
    private homeService: HomeService,
    private router: Router,
    private chatService: ChatService
  ) {}

  ngOnInit(): void {
    console.log(this.datas)
    console.log(this.relatedUser)
    if(!this.datas) return
    this.datas.user.loaded = false;
    this.datas.user.error = false;

    if(!this.datas.time) return

    this.checkIfTodayOrYesterday(this.datas.time);

    this.userTinyService.confirm.subscribe((ret) => {
      if (!ret.display) {
        this.actions = false;
      }
    });

    this.userTinyService.success.subscribe((res) => {
      if(!this.datas) return
      if (res.feed && res.feed === this.datas.feedId) {
        if (this.datas.actions.removePhoto) {
          this.removeItem();
        }
        if (this.datas.actions.removeStory) {
          this.removeItem();
        }
      }
    });
  }

  checkIfTodayOrYesterday(time: number) {
    if(!this.datas) return

    const today = new Date();
    const date = new Date(time).toDateString();
    const now = today.toDateString();
    const yesterday = new Date(today.setDate(today.getDate() - 1)).toDateString();
    this.datas.today = date === now;
    this.datas.yesterday = date === yesterday;
  }

  showHideActions() {
    this.actions = !this.actions;
  }

  imageLoaded() {
    if(!this.datas) return
    this.datas.user.loaded = true;
  }

  imageError() {
    if(!this.datas) return
    this.datas.user.error = true;
  }

  changeRelationship(state: string) {
    this.updating = true;
    this.actions = false;

    if(!this.datas) return

    this.homeService.updateRelationship(this.datas.user.id, state).subscribe((res) => {
      console.log(res)
      this.updating = false;
      // this.angulartics2.eventTrack.next({
      //   action: state === 'validated' ? 'clic_accepter_ami' : 'clic_refuser_ami',
      //   properties: {
      //     category: 'cat_contact',
      //   },
      // });
      if(!this.datas) return
      this.confirmOn.emit({ id: this.datas.user.id, add: state === 'validated' });
    });
  }

  throwConfirm(type: string, value?: string){
    console.log('throwConfirm: ', type, value)
  }

  // throwConfirm(type: string, value?: string): void {
  //   //this.serviceService.hideFooter(!!type);

  //   const datas = {
  //     display: true,
  //     value: value,
  //     type,
  //     user: {
  //       name: this.datas.user.firstname,
  //       id: this.datas.user.id,
  //       gendre: this.datas.user.gendre,
  //     },
  //     talking: this.datas.talking,
  //     feedId: this.datas.feedId,
  //   };
  //   this.userTinyService.throwConfirm(datas);
  //   if (!type) {
  //     this.showHideActions();
  //   }
  // }

  removeItem() {
    if(!this.datas) return
    this.confirmOn.emit(this.datas.feedId);
  }

  viewProfile() {
    if(!this.datas) return
    this.redirect.emit(true);
    if (!this.datas.me) {
      if (this.feed) {
        // this.angulartics2.eventTrack.next({
        //   action: 'clic_profils',
        //   properties: {
        //     category: 'cat_feed',
        //   },
        // });
      }
      this.router.navigate(['/service/profil/', this.datas?.user.id]);
    } else {
      //this.serviceService.showHideProfil(true);
    }
  }

  redirectLink() {
    if (!this.datas?.me) {
      //this.router.navigate([this.link]);
      if (this.feed) {
        // this.angulartics2.eventTrack.next({
        //   action: 'clic_profils',
        //   properties: {
        //     category: 'cat_feed',
        //   },
        // });
      }
    } else {
      // this.redirect.emit(true);
      //this.serviceService.showHideProfil(true);
    }
  }

  openTalk(){
    this.chatService.talkingId.next(this.datas?.talking)
    this.talkSelected.emit(this.datas?.talking)
    this.toggleSidenav(true, 'conversation')
    console.log('openTalk')
    this.chatService.openConversation.next(true)
  }

  closeTalk(){
    this.chatService.talkingId.next(null)
    this.talkSelected.emit(null)
    this.toggleSidenav(false, '')
    console.log('closeTalk')
    this.chatService.openConversation.next(false)
    this.router.navigate(['/home/my-matches/chat'])
  }

  toggleSidenav(open: boolean, componentRequested: string){
    this.chatService.toggleSidenav.next(open)
    this.chatService.componentDisplayed.next(componentRequested)
  }
}

