<mat-sidenav-container
  class="sidenav-container"
  [ngStyle]="{
    'background-color': 'transparent',
    'z-index': conversationSidenav.opened ? '3' : '1',
    'position' : conversationSidenav.opened ? 'fixed' : 'relative'
  }"
>
  <mat-sidenav
    #conversationSidenav
    [mode]="desktopDevice ? 'side' : 'push'"
    class="secondary-sidenav"
    position="end"
    [opened]="desktopDevice"
  >
    <!-- contenu additionnel -->
    @if(!showConversation){
      <div class="conversations-current">
        <div class="icon-container">
            <img src="../../assets/icons/icon_chat_with.svg" alt="">
        </div>
        <h3>My conversations</h3>
      </div>
    }
    
    <router-outlet></router-outlet>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="list">
        @if(talks && talks.length > 0) {
          <div class="list-conversations">
            @for(talk of talks; track $index){
              <div
                class="conversation pop"
                [class.closed]="talk.closed"
                [class.silent]="talk.warning_silent_talking && !talk.myMessage"
              ><!-- [class.unread]="!talk.readed" -->
                <hr class="separation-line">
                <app-user-tiny
                  [datas]="talk.userDatas"
                  (click)="setAsRead(talk);"
                  [direction]="'vertical'"
                  [talkIsRead]="talk.readed"
                  [feed]="false"
                  (talkSelected)="getTalkSelected($event)"
                ><!-- (confirmOn)="showHideHeader($event)" -->
                </app-user-tiny><!-- [link]="'/home/my-matches/conversation/' + talk.id" -->
    
                @if(talk.warning_silent_talking && !talk.myMessage){
                  <button type="button"
                    class="direct-message"
                    (click)="setAsRead(talk)"
                    [routerLink]="'/home/my-matches/chat/conversation/' + talk.id"
                  ><!--  [routerLink]="'/service/chat/conversation/' + talk.id" -->
                    plus icon

                    <span>Cette personne vous a envoyé un message et souhaite vous ajouter en contact</span>
                  </button>
                }
              </div>
            }
          </div>
        } @else {
            <div class="empty-module">
              <div class="empty-box">
                message icon
              </div>
              <h2>Ooops</h2>
              <p>You don't have any conversation</p>
            </div>
        }
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
