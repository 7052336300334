import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { BehaviorSubject, concatMap, map, Observable, Subject, Subscription, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { AppService } from '../app.service';
import { MatchesService } from './matches.service';

import { LoaderComponent } from '../shared/loader/loader.component';
import { MatchComponent } from './match/match.component';

import { User } from '../shared/interfaces/user';
import { Talk } from '../shared/interfaces/talk';
import { Notification } from '../shared/interfaces/notification';
import { NotificationCount } from '../shared/interfaces/notification-count';

@Component({
  selector: 'app-my-matches',
  standalone: true,
  imports: [LoaderComponent, MatchComponent, RouterModule, CommonModule],
  templateUrl: './my-matches.component.html',
  styleUrl: './my-matches.component.scss'
})
export class MyMatchesComponent implements OnInit, OnDestroy {
  loading = false
  matchesLoading = true
  profiles!: User[]
  talks!: Talk[]
  loaderSubscription!: Subscription
  notifications: Notification[] = []
  notificationCount = 0
  matches!: User[]
  notificationObservable$!: Observable<unknown>
  private stop$ = new Subject<void>()
  private start$ = new Subject<void>()
  private liveNotifications$ = new Subject<NotificationCount>()

  notificationsCount = new BehaviorSubject(0)

  constructor(
    private appService: AppService,
    private http: HttpClient,
    private router: Router,
    private matchesService: MatchesService
  ){}

  ngOnInit(){
    this.loaderSubscription = this.appService.loader.subscribe(res => this.loading = res)

    // gestion taille écran
    if(window.screen.width >= 1280 && window.screen.height >= 600) {
      this.appService.desktopDevice = true
    }

    //this.getTalks().subscribe(res => this.talks = res)

    timer(0, 20000)
    .pipe(concatMap(() => {
      return this.matchesService.getNotificationsCount().pipe(
        map(({ invitations, messages, new_contacts, news }) => {
          const notifs = {
            exist: invitations.exist || new_contacts.exist || news.exist,
            count: news.count + invitations.count,
          }

          const res = {
            messages: messages as { exist: boolean; count: number },
            notifs: notifs as { exist: boolean; count: number },
            invitations,
            contacts: new_contacts,
            news,
          }

          this.liveNotifications$.next(res)
          return res
        })
      );
    }))
    .subscribe(res => {
      this.notificationsCount.next(res.notifs.count)
    });

    this.matchesService.getMatches().subscribe(res => {
      this.matchesLoading = false
      this.matches = res
    })
  }

  private _isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getUTCFullYear() === date2.getUTCFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }

  private _getSeparatorBody(date: Date): string {
    let timeStr = ''

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const twoDaysBeforeToday = new Date()
    twoDaysBeforeToday.setDate(twoDaysBeforeToday.getDate() - 2)

    const threeDaysBeforeToday = new Date()
    threeDaysBeforeToday.setDate(threeDaysBeforeToday.getDate() - 3)

    if (this._isSameDay(date, new Date())) {
      timeStr = 'Today'
    } else if (this._isSameDay(date, yesterday)) {
      timeStr = 'Yesterday'
    } else if (this._isSameDay(date, twoDaysBeforeToday)) {
      timeStr = '2 days ago'
    } else if (this._isSameDay(date, threeDaysBeforeToday)) {
      timeStr = '3 days ago'
    } else {
      timeStr = 'More than a week ago'
    }

    return timeStr
  }

  // récupération de fake profiles : pour les tests
  // getProfiles(): Observable<User[]> {
  //   return this.http.get<User[]>('./assets/users.json').pipe(map((res) => {
  //     return res
  //   }))
  // }

  // récupération de fake Talks : pour les tests
  // getTalks(): Observable<Talk[]> {
  //   return this.http.get<Talk[]>('./assets/talks.json').pipe(map((res) => {
  //     return res
  //   }))
  // }

  showNotifications(){
    this.router.navigate(['home/my-matches/notifications'])
  }

  ngOnDestroy(){
    this.loaderSubscription.unsubscribe()
  }
}
